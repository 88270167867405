const required = value => (value ? undefined : 'Required');
const number = value => (value && isNaN(Number(value)) ? 'Must be a number' : undefined);
const nondecimal = value => (value && !/^[0-9]{1,9}$/i.test(value) ? 'Must be a non decimal number' : undefined);
const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email address' : undefined;

const maxLength = max => value => (value && value.length > max ? `Length must be less than ${max}` : undefined);

const maxLength64 = maxLength(64);
const maxLength36 = maxLength(36);
const maxLength20 = maxLength(20);
const maxLength13 = maxLength(13);

const onlyChar = value => (value && !/^[a-zA-Z ]*$/i.test(value) ? 'Field contains illegal character' : undefined);

const charVal = value => (value && !/^[a-zA-Z ]*$/i.test(value) ? 'Field contains illegal character' : undefined);

const customerAddress = value => (value && !/^[a-zA-Z0-9\s,'.-]*$/i.test(value) ? 'Invalid Address' : undefined);

const phoneNumber = value =>
    value && !/^(0|[1-9][0-9]{9})$/i.test(value) ? 'Invalid phone number, must be 10 digits' : undefined;

export {
    required,
    number,
    nondecimal,
    maxLength64,
    maxLength36,
    maxLength20,
    email,
    onlyChar,
    maxLength13,
    customerAddress,
    phoneNumber,
    charVal
};
