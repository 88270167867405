import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import * as StyledCommon from '../page.styled';
import { Header } from '../includes';
import { flyWheel, drivingEquipment, VIEWS, SIZE, MOTORSIZE, drivingEquipmentValues } from './options';
import {
    SelectField,
    renderInput1,
    SelectField1,
    renderInputCoupling,
    renderSelectField,
    renderInputHidden
} from '../../constants/redux-form';
import { number, nondecimal, required } from '../../constants/validations';
import {
    searchCoupling,
    couplingInformation,
    getMaxSpeed,
    getDrivingDiameter,
    getDrivenDiameter,
    fetchApplicationList,
    getCouplingUom,
    getResultEmpty,
    getBrowseEmpty,
    getSearchFormCoupling,
    getDrivenEquipmentValue,
    couplingTypeChange
} from '../../actions';
import { PATHS } from '../../constants';

/*
 TYPES
*/

type Props = {
  handleSubmit: Function,
  searchCoupling: Function,
  getMaxSpeed: Function,
  drivingDiameter: Function,
  getDrivenDiameter: Function,
  getDrivingDiameter: Function,
  fetchApplicationList: Function,
  history: Object,
  maxSpeed: Object,
  drivenDiameter: Object,
  couplingInformation: Object,
  application: Object,
  getCouplingUom: Function,
  couplingUom: Object,
  searchForm: Object,
  getSearchFormCoupling: Function,
  getResultEmpty: Function,
  initialValues: Object,
  getDrivenEquipmentValue: Function,
  getCouplingTypeValue: Object,
  couplingTypeChange: Function
};

type State = {
  selectedView: string,
  selectedSize: string,
  selectedMotor: string,
  data: Object,
  selectedDrivingEquipment: string
};

let formValue = JSON.parse(localStorage.getItem('formValues'));

class Search extends Component<Props, State> {
    constructor (props) {
        super(props);
        this.state = {
            data: [],
            couplingType1: '',
            selectedView: '',
            selectedSize: 'Select Coupling Type',
            selectedDrivingEquipment: '',
            selectedMotor: 'Select Type',
            engagementType: '',
            coupling: '',
            couplingType: '',
            couplingSize: '',
            motorSize: '',
            result: 0,
            kW: 0,
            RPM: 0,
            warning: '',
            warningdiameter: '',
            warningdrivendiameter: '',
            dynamicDrivenOptions: [],
            drivenEquipmentLbl: '',
            count: 1,
            radioSelected: 0
        };
        this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
        this.toMain = React.createRef();
        this.handlenum1Change = this.handlenum1Change.bind(this);
        this.handlenum2Change = this.handlenum2Change.bind(this);
        this.handledrivingShaftDiameterChange = this.handledrivingShaftDiameterChange.bind(this);
        this.handledrivenShaftDiameterChange = this.handledrivenShaftDiameterChange.bind(this);
    }

    componentWillReceiveProps (nextProps) {
        localStorage.clear();
        localStorage.removeItem('formValues');
        localStorage.setItem('searchForm', '');
        if (nextProps.searchForm) {
            localStorage.removeItem(nextProps.searchForm.motorType);

            if (nextProps.getCouplingTypeValue) {
                localStorage.setItem('getCouplingTypeValue', nextProps.getCouplingTypeValue);
            }
            this.setState({
                ratedPower: nextProps.searchForm ? nextProps.searchForm.ratedPower : formValue.ratedPower,
                speed: nextProps.searchForm ? nextProps.searchForm.speed : formValue.speed,
                drivenShaftDiameter: nextProps.searchForm
                    ? nextProps.searchForm.drivenShaftDiameter
                    : formValue.drivenShaftDiameter,
                application: nextProps.searchForm ? nextProps.searchForm.application : formValue.application,
                coupling: nextProps.searchForm ? nextProps.searchForm.coupling : formValue.coupling,
                engagementType: nextProps.searchForm ? nextProps.searchForm.engagementType : formValue.engagementType,
                drivingequipment: nextProps.searchForm ? nextProps.searchForm.drivingequipment : formValue.drivingequipment,
                couplingType: nextProps.searchForm ? nextProps.searchForm.couplingType : formValue.couplingType,
                uom: nextProps.searchForm ? nextProps.searchForm.uom : formValue.uom,
                uomIn: nextProps.searchForm ? nextProps.searchForm.uomIn : formValue.uomIn,
                drivenequipment: nextProps.searchForm ? nextProps.searchForm.drivenequipment : formValue.drivenequipment,
                drivingShaftDiameter: nextProps.searchForm
                    ? nextProps.searchForm.drivingShaftDiameter
                    : formValue.drivingShaftDiameter,
                flywheel: nextProps.searchForm ? nextProps.searchForm.flywheel : formValue.flywheel,
                nominaltorque: nextProps.searchForm ? nextProps.searchForm.nominaltorque : formValue.nominaltorque
            });
        }

        if (nextProps.searchForm && nextProps.searchForm.couplingType === 'PM') {
            this.setState({
                motorType: nextProps.searchForm ? nextProps.searchForm.motorType : formValue.motorType,
                motorSize: nextProps.searchForm ? nextProps.searchForm.motorSize : formValue.motorSize
            });
        }
    }

    getValues = e => {
        if (e.target.name === 'uomIn') {
            this.setState({ uomIn: e.target.value });
        }
    };

    componentDidMount () {
        if (localStorage.getItem('searchForm') === null) {
            window.location.reload();
        }

        this.setState({ count: 1 });

        if (localStorage.getItem('formValues') === null) {
            if (this.state.count == 1) {
                // window.location.reload();
            }
        }
        if (this.props.initialValues.uomIn === 'mm') {
            this.setState({ radioSelected: 'mm' });
        }
        if (this.props.initialValues.uomIn === 'in') {
            this.setState({ radioSelected: 'in' });
        }

        localStorage.clear();
        this.props.getResultEmpty();
        this.props.getDrivenEquipmentValue();
        this.props.getCouplingUom();
        this.props.getMaxSpeed();
        this.props.getDrivingDiameter();
        this.props.getDrivenDiameter();
        this.props.fetchApplicationList();
        setTimeout(() => {
            this.toMain.current.scrollIntoView({ behavior: 'smooth' });
        }, 500);

        if (this.props.searchForm) {
            let driven = this.props.application.data.filter(item =>
                item.name === this.props.searchForm.application ? item['drivenEquipment'] : false
            );
            this.setState({
                selectedView: this.props.searchForm.coupling,
                selectedSize: this.props.searchForm.couplingType,
                couplingSize: this.props.searchForm.couplingSize,
                engagementType: this.props.searchForm.engagementType,
                selectedMotor: this.props.searchForm.motorType,
                motorSize: this.props.searchForm.couplingType === 'PM' ? this.props.searchForm.motorSize : '',
                motorType: this.props.searchForm.couplingType === 'PM' ? this.props.searchForm.motorType : '',
                flyWheel:
          this.props.searchForm.engagementType === 'Standard SAE Flywheel to Shaft'
              ? this.props.searchForm.flyWheel
              : '',
                drivenEquipmentLbl: this.props.searchForm.drivenequipment,
                dynamicDrivenOptions: driven.length > 0 ? driven[0].drivenEquipment : [],
                kW: this.props.searchForm.ratedPower,
                RPM: this.props.searchForm.speed,
                couplingType: this.props.searchForm.couplingType
            });
        }

        localStorage.setItem('confirmPage', '');
        localStorage.setItem('firstLoad', '');
    }

    handlenum1Change (evt) {
        this.setState({ kW: Number(evt.target.value) });

        var name = evt.target.name;
        this.setState({ [name]: evt.target.value });
    }
    onRadioBtnClick (radioSelected) {
        this.setState({
            radioSelected: radioSelected
        });
    }
    handlenum2Change (evt) {
        this.setState({ RPM: Number(evt.target.value) });
        let warning = '';
        if (this.props.maxSpeed.data) {
            let speed = this.props.maxSpeed.data.maxSpeed * 0.8;
            if (evt.target.value > speed) {
                warning = (
                    <StyledCommon.WarningMessage>
                        <strong>Warning!</strong> Speed is `&gt; 80% of max coupling speed. Balancing recommended.
                    </StyledCommon.WarningMessage>
                );
            }
            this.setState({ warning: warning });
        }
        return false;
    }

    handledrivingShaftDiameterChange (evt) {
        let warningdiameter = '';
        if (this.props.drivingDiameter.data) {
            var maxdiameter = this.props.drivingDiameter.data.maxDriveFlangeBore;
            var mindiameter = this.props.drivingDiameter.data.minDriveFlangeBore;
            if (evt.target.value >= mindiameter && evt.target.value <= maxdiameter) {
                this.setState({ warningdiameter: '' });
                return true;
            } else {
                warningdiameter = (
                    <StyledCommon.WarningMessage>
                        <strong>Warning!</strong> Driving Shaft Diameter{' '}
                    </StyledCommon.WarningMessage>
                );
                this.setState({ warningdiameter: warningdiameter });
            }
        }
        return false;
    }

    handledrivenShaftDiameterChange (evt) {
        let warningdrivendiameter = '';

        if (this.props.drivenDiameter.data) {
            var maxdiameter = this.props.drivenDiameter.data.maxDrivenFlangeBore;
            var mindiameter = this.props.drivenDiameter.data.minDrivenFlangeBore;
            if (evt.target.value >= mindiameter && evt.target.value <= maxdiameter) {
                this.setState({ warningdrivendiameter: '' });
                return true;
            } else {
                warningdrivendiameter = (
                    <StyledCommon.WarningMessage>
                        <strong>Warning!</strong> Driving Shaft Diameter{' '}
                    </StyledCommon.WarningMessage>
                );
                this.setState({ warningdrivendiameter: warningdrivendiameter });
            }
        }
        return false;
    }

    onSubmit = formValues => {
        if ((formValues && formValues.couplingType === 'RB') || formValues.couplingType === 'RBI') {
            this.setState({ motorType: delete formValues.motorType, motorSize: delete formValues.motorSize });
        }

        localStorage.setItem('formValues', JSON.stringify(formValues));

        let Nominaltorquetkn = 0;
        let Maxtorquetkmax = 0;
        let Nominaltorquetknsearch = 0;
        let x = this.state.kW / this.state.RPM;

        let fmValue = formValues.drivenequipment.split('#')[0];
        let fp = formValues.drivingequipment ? drivingEquipmentValues[formValues.drivingequipment] : '';
        let fm = formValues.drivenequipment ? parseFloat(fmValue) : '';
        let u = parseFloat(fp) + parseFloat(fm);

        if (formValues.uomIn == 'mm') {
            formValues.drivenShaftDiameter = (formValues.drivenShaftDiameter * 0.03937).toString();
            formValues.drivingShaftDiameter = (formValues.drivingShaftDiameter * 0.03937).toString();
        }

        let uom = '';

        let equipment = [
            'Diesel_Engine_1_Cylinder',
            'Diesel_Engine_2_Cylinder',
            'Diesel_Engine_3_Cylinder',
            'Diesel_Engine_4_Cylinder',
            'Diesel_Engine_5_Cylinder',
            'Diesel_Engine_6_Cylinder',
            'Diesel_Engine_6plus_Cylinder'
        ];

        formValues.drivingEquipmentDropdown = equipment;
        // if (this.state.couplingType == 'RB') {
        //     formValues.couplingType = this.state.couplingType;
        // }

        if (formValues.uom === 'kw') {
            let y = 9549 * x;
            uom = 'kNm';
            Nominaltorquetkn = y / 1000;
            Maxtorquetkmax = Nominaltorquetkn * u;
            Nominaltorquetknsearch = 5252 * x;
            formValues.maxTorque = Nominaltorquetknsearch * u;
            formValues.nominaltorque = Nominaltorquetknsearch.toFixed(2);
        } else if (formValues.uom === 'hp') {
            Nominaltorquetkn = 5252 * x;
            uom = 'lb-ft';
            Maxtorquetkmax = Nominaltorquetkn * u;
            formValues.maxTorque = Maxtorquetkmax;
            formValues.nominaltorque = Nominaltorquetkn.toFixed(2);
        }

        this.setState({ result: Nominaltorquetkn });
        let information = {
            fp: fp,
            fm: fm,
            nominaltorque: Nominaltorquetkn.toFixed(2),
            Maxtorquetkmax: Maxtorquetkmax.toFixed(2),
            maxtorqueuom: uom,
            application: formValues.application,
            drivingequipment: formValues.drivingequipment,
            drivenequipment: formValues.drivenequipment.split('#')[1],
            speed: this.state.RPM,
            ratedpower: formValues.ratedPower,
            uom: formValues.uom,
            drivenShaftDiameter: formValues.drivenShaftDiameter,
            drivingShaftDiameter: formValues.drivingShaftDiameter,
            setUom: formValues.uomIn
        };

        // return false;
        this.props.couplingInformation(information);

        this.props.searchCoupling(formValues);
        this.props.getSearchFormCoupling(formValues);
        this.setState({ data: formValues });

        this.props.history.push(PATHS.RESULTS);
    };

    handleChange = (event, data) => {
        this.setState({
            engagementType: data,
            drivenequipment: data,
            couplingSize: data,
            couplingType: data,
            motorSize: data,
            drivingequipment: data
        });
    };

    handleCouplingType = (e, data) => {
        this.setState({ couplingType1: data });
        this.props.couplingTypeChange(data);
        this.setState({
            selectedSize: data,
            couplingSize: data,
            couplingType: data,
            motorSize: data,
            selectedDrivingEquipment: data
        });
    };

    handleCouplingChange = (e, data) => {
        this.setState({
            selectedView: e.target.value,
            coupling: data
        });
    };

    // couplingTypeChange = (event, data) => {
    //     this.setState({ couplingType: data });
    // };

    onDrivenEquipmentChange = e => {
        let drivenequipment = this.props.application.data.filter(item =>
            item.name === e.target.value ? item['drivenEquipment'] : false
        );

        this.setState({ dynamicDrivenOptions: drivenequipment.length > 0 ? drivenequipment[0].drivenEquipment : [] });
    };

    drivenequipment = e => {
        let index = e.nativeEvent.target.selectedIndex;
        let label = e.nativeEvent.target[index].text;
        this.props.getDrivenEquipmentValue(label);
        // localStorage.setItem('drivenEquipmentLbl', label);
        this.setState({ drivenEquipmentLbl: label });
    };

    drivingEquipmentChange = () => {
        /** /let equipment = [
            'Diesel_Engine_1_Cylinder',
            'Diesel_Engine_2_Cylinder',
            'Diesel_Engine_3_Cylinder',
            'Diesel_Engine_4_Cylinder',
            'Diesel_Engine_5_Cylinder',
            'Diesel_Engine_6_Cylinder',
            'Diesel_Engine_6plus_Cylinder'
        ];
        let equipData = equipment.indexOf(data); /**/
        // if (equipData >= 0) {
        //     this.setState({ couplingType: 'RB' });
        // }
    };
    render () {
        let maxdiameter = 0;
        let mindiameter = 0;
        let maxdiameterDriven = 0;
        let mindiameterDriven = 0;
        if (this.props.drivingDiameter.data) {
            maxdiameter = this.props.drivingDiameter.data.maxDriveFlangeBore;
            mindiameter = this.props.drivingDiameter.data.minDriveFlangeBore;
        }
        if (this.props.drivenDiameter.data) {
            maxdiameterDriven = this.props.drivenDiameter.data.maxDrivenFlangeBore;
            mindiameterDriven = this.props.drivenDiameter.data.minDrivenFlangeBore;
        }

        const getEngagementType = () => {
            const view = VIEWS.filter(({ name }) => name === this.state.selectedView)[0];

            return (
                <div>
                    <div className="mb-2 formDiv">
                        <StyledCommon.FormLabel>
              Engagement Type<em className="required">*</em>
                        </StyledCommon.FormLabel>
                        <div className="field">
                            <Field
                                name="engagementType"
                                validate={[required]}
                                onChange={this.handleChange}
                                label="Engagement Type"
                                component={renderSelectField}
                                value={this.state.engagementType}
                            >
                                <option value="">Select Engagment Type</option>

                                {view
                                    ? view.value.map(m => (
                                        <option value={m} key={m}>
                                            {m}
                                        </option>
                                    ))
                                    : ''}
                            </Field>
                        </div>
                    </div>
                </div>
            );
        };

        const getDrivenEquipment = () => (
            <div>
                <div className="mb-2 formDiv">
                    <StyledCommon.FormLabel>
            Driven Equipment <em className="required">*</em>
                    </StyledCommon.FormLabel>
                    <div className="field">
                        <Field
                            name="drivenequipment"
                            validate={[required]}
                            onChange={this.drivenequipment}
                            label="Driven Equipment"
                            component={renderSelectField}
                        >
                            <option value="">Select Driven Equipment</option>
                            {this.state.dynamicDrivenOptions.map(function (data, key) {
                                return (
                                    <option key={key} value={`${data.value}#${data.label}`}>
                                        {data.label}
                                    </option>
                                );
                            })}
                        </Field>
                    </div>
                </div>
            </div>
        );

        const sizeOfCoupling = () => {
            const size = SIZE.filter(({ name }) => name === this.state.selectedSize)[0];

            if (size) {
                return (
                    <div>
                        <Field name="couplingSize" label="Coupling Size" component={SelectField} onChange={this.handleChange}>
                            <option value="">Select Size</option>
                            {size.value.map(p => (
                                <option key={p}>{p}</option>
                            ))}
                        </Field>
                    </div>
                );
            }
        };

        const getCouplingType = () => {
            if (this.state.coupling === 'Half Coupling – Flex' && this.state.engagementType === 'Mill Motor') {
                return (
                    <div>
                        <Field name="couplingType" onChange={this.handleCouplingType} label="Coupling Type" component={SelectField}>
                            <option value="">Select Coupling Type</option>
                            <option value="PM">PM</option>
                        </Field>
                    </div>
                );
            } else {
                return (
                    <div>
                        <Field name="couplingType" onChange={this.handleCouplingType} label="Coupling Type" component={SelectField}>
                            <option value="">Select Coupling Type</option>
                            {SIZE.map(function (data, key) {
                                return (
                                    <option key={key} value={data.key}>
                                        {data.name}
                                    </option>
                                );
                            })}
                        </Field>
                    </div>
                );
            }
        };

        const DrivingEquipment = () => {
            const driequ = drivingEquipment.filter(({ name }) => name === this.state.selectedDrivingEquipment)[0];
            if (driequ) {
                return (
                    <div>
                        <div className="mb-2 formDiv">
                            <StyledCommon.FormLabel>
                Driving Equipment<em className="required">*</em>
                            </StyledCommon.FormLabel>
                            <div className="field">
                                <Field
                                    name="drivingequipment"
                                    validate={[required]}
                                    label="Driving Equipment"
                                    component={renderSelectField}
                                    onChange={this.drivingEquipmentChange}
                                >
                                    {driequ.value.map(function (data) {
                                        return (
                                            <option key={data.key} value={data.name}>
                                                {data.value}
                                            </option>
                                        );
                                    })}
                                </Field>
                            </div>
                        </div>
                    </div>
                );
            } else {
                const driequ = drivingEquipment.filter(({ name }) => name === 'Select Driving Equipment')[0];
                return (
                    <div>
                        <div className="mb-2 formDiv">
                            <StyledCommon.FormLabel>
                Driving Equipment<em className="required">*</em>
                            </StyledCommon.FormLabel>
                            <div className="field">
                                <Field
                                    name="drivingequipment"
                                    validate={[required]}
                                    label="Driving Equipment"
                                    component={renderSelectField}
                                    onChange={this.drivingEquipmentChange}
                                >
                                    {driequ.value.map(function (data) {
                                        return (
                                            <option key={data.key} value={data.name}>
                                                {data.value}
                                            </option>
                                        );
                                    })}
                                </Field>
                            </div>
                        </div>
                    </div>
                );
            }
        };

        const getMotorSize = () => {
            const view = MOTORSIZE.filter(({ name }) => name === this.state.selectedMotor)[0];
            if (view) {
                return (
                    <div>
                        <Field name="motorSize" label="Motor Frame Size" component={SelectField}>
                            <option value="">Select Size</option>
                            {view.value.map(m => (
                                <option key={m}>{m}</option>
                            ))}
                        </Field>
                    </div>
                );
            }
        };

        const flywheel =
      this.state.engagementType === 'Standard SAE Flywheel to Shaft' ? (
          <div>
              <Field name="flywheel" label="Flywheel" component={SelectField}>
                  <option value="">Select Flywheel</option>
                  {flyWheel.map(function (data, key) {
                      return (
                          <option key={key} value={data.key}>
                              {data.value}
                          </option>
                      );
                  })}
              </Field>
          </div>
      ) : null;

        const motorType =
      this.state.couplingType === 'PM' ? (
          <div>
              <Field
                  name="motorType"
                  label="Motor Type"
                  onChange={e => this.setState({ selectedMotor: e.target.value })}
                  component={SelectField}
              >
                  <option value="">Motor Type</option>
                  {MOTORSIZE.map(function (data, key) {
                      return (
                          <option key={key} value={data.key}>
                              {data.name}
                          </option>
                      );
                  })}
              </Field>
              {getMotorSize()}
          </div>
      ) : null;

        return (
            <StyledCommon.BottomBarPb ref={this.toMain}>
                <StyledCommon.Wrapper>
                    <Header />
                    <StyledCommon.Container>
                        <h1 className="page-heading">Search Details</h1>
                        <div className="pt-4 form-section">
                            <form
                                onSubmit={this.props.handleSubmit(this.onSubmit)}
                                name="searchcoupling"
                                className="contactform"
                                ref={el => (this.myFormRef = el)}
                            >
                                <div className="mb-2 formDiv">
                                    <StyledCommon.FormLabel>
                    Use app in inches or milimeters?<em className="required">*</em>
                                    </StyledCommon.FormLabel>
                                    <div className="field">
                                        <StyledCommon.ContactButtons className="m-none mb-15">
                                            <StyledCommon.RadioButtonsLabel onClick={() => this.onRadioBtnClick('in')}>
                                                <Field
                                                    name="uomIn"
                                                    component={renderInputHidden}
                                                    type="radio"
                                                    value="in"
                                                    validate={[required]}
                                                    onChange={this.getValues}
                                                />
                                                <StyledCommon.ContactButtonLink
                                                    className={`first-radio ${this.state.radioSelected === 'in' ? 'active-radio' : ''}`}
                                                >
                          Inches
                                                </StyledCommon.ContactButtonLink>
                                            </StyledCommon.RadioButtonsLabel>
                                            <StyledCommon.RadioButtonsLabel onClick={() => this.onRadioBtnClick('mm')}>
                                                <Field
                                                    name="uomIn"
                                                    validate={[required]}
                                                    component={renderInputHidden}
                                                    type="radio"
                                                    value="mm"
                                                    onChange={this.getValues}
                                                />
                                                <StyledCommon.ContactButtonLink
                                                    className={`${this.state.radioSelected === 'mm' ? 'active-radio' : ''}`}
                                                >
                          Millimeters
                                                </StyledCommon.ContactButtonLink>
                                            </StyledCommon.RadioButtonsLabel>
                                        </StyledCommon.ContactButtons>
                                    </div>
                                </div>

                                <div className="mb-2 formDiv">
                                    <StyledCommon.FormLabel>
                    Full or Half Coupling<em className="required">*</em>
                                    </StyledCommon.FormLabel>
                                    <div className="field">
                                        <Field
                                            name="coupling"
                                            validate={required}
                                            onChange={this.handleCouplingChange}
                                            component={renderSelectField}
                                        >
                                            <option value="">Select Coupling</option>
                                            {VIEWS ? VIEWS.map(({ name }) => <option key={name}>{name}</option>) : ''}
                                        </Field>
                                    </div>
                                </div>

                                {getEngagementType()}

                                {getCouplingType()}

                                {sizeOfCoupling()}

                                <div className="mb-2 maindiv">
                                    <StyledCommon.FormLabel>
                    Rated Power<em className="required">*</em>
                                    </StyledCommon.FormLabel>

                                    <StyledCommon.TwoField>
                                        <Field
                                            name="ratedPower"
                                            type="text"
                                            data={this.state.ratedPower}
                                            placeholder="Rated Power"
                                            component={renderInput1}
                                            validate={[number, required, nondecimal]}
                                            onChange={this.handlenum1Change}
                                        />

                                        <Field name="uom" validate={[required]} component={SelectField1}>
                                            <option value="">Select</option>
                                            <option value="kw">Kilowatt</option>
                                            <option value="hp">Horse Power</option>
                                        </Field>
                                    </StyledCommon.TwoField>
                                </div>

                                <div className="mb-2 formDiv">
                                    <StyledCommon.FormLabel>
                    Speed (rpm)<em className="required">*</em>
                                    </StyledCommon.FormLabel>
                                    <div className="field">
                                        <Field
                                            name="speed"
                                            type="text"
                                            component={renderInputCoupling}
                                            validate={[number, nondecimal, required]}
                                            label="Speed (rpm)"
                                            onChange={this.handlenum2Change}
                                        />
                                    </div>
                                </div>

                                {this.state.warning}

                                {DrivingEquipment()}

                                <div className="mb-2 formDiv">
                                    <StyledCommon.FormLabel>
                    Driving Shaft Diameter{' '}
                                        {this.state.radioSelected === 'in' ? '(in)' : '' || this.state.radioSelected === 'mm' ? '(mm)' : ''}
                                        <em className="required">*</em>
                                        <div className="information">
                      Note: Insert diameter between{' '}
                                            {this.state.radioSelected === 'mm' ? (mindiameter / 0.03937).toFixed(2) : mindiameter.toFixed(2)}{' '}
                      and{' '}
                                            {this.state.radioSelected === 'mm' ? (maxdiameter / 0.03937).toFixed(2) : maxdiameter.toFixed(2)}.
                                        </div>
                                    </StyledCommon.FormLabel>
                                    <div className="field">
                                        <Field
                                            name="drivingShaftDiameter"
                                            type="text"
                                            component={renderInputCoupling}
                                            validate={[number, required]}
                                            onChange={this.handledrivingShaftDiameterChange}
                                        />
                                    </div>
                                </div>

                                {/* <div className="mb-2 maindiv">
                                    <StyledCommon.FormLabel>
                    Driving Shaft Diameter {this.state.uomIn}<em className="required">*</em>
                                        <div className="information">
                      Note: Insert diameter between {mindiameter} and {maxdiameter}.
                                        </div>
                                    </StyledCommon.FormLabel>

                                    <StyledCommon.TwoField>
                                        <Field
                                            name="drivingShaftDiameter"
                                            type="text"
                                            component={renderInputCoupling}
                                            validate={[number, required]}
                                            onChange={this.handledrivingShaftDiameterChange}
                                        />

                                        <Field name="uomIn" validate={[required]} component={SelectFieldUom}>
                                            <option value="">Select</option>
                                            <option value="In">In</option>
                                            <option value="MM">MM</option>
                                        </Field>
                                    </StyledCommon.TwoField>
                                </div> */}

                                <div className="mb-2 formDiv">
                                    <StyledCommon.FormLabel>
                    Application <em className="required">*</em>
                                    </StyledCommon.FormLabel>
                                    <div className="field">
                                        <Field
                                            name="application"
                                            validate={[required]}
                                            label="Application"
                                            component={renderSelectField}
                                            onChange={this.onDrivenEquipmentChange}
                                        >
                                            <option value="">Select Application</option>
                                            {this.props.application.data
                                                ? this.props.application.data.map(({ name, _id }) => (
                                                    <option key={_id} value={name}>
                                                        {name}
                                                    </option>
                                                ))
                                                : null}
                                        </Field>
                                    </div>
                                </div>

                                {getDrivenEquipment()}

                                <div className="mb-2 formDiv">
                                    <StyledCommon.FormLabel>
                    Driven Shaft Diameter{' '}
                                        {this.state.radioSelected === 'in' ? '(in)' : '' || this.state.radioSelected === 'mm' ? '(mm)' : ''}
                                        <em className="required">*</em>
                                        <div className="information">
                      Note: Insert diameter between{' '}
                                            {this.state.radioSelected === 'mm'
                                                ? (mindiameterDriven / 0.03937).toFixed(2)
                                                : mindiameterDriven.toFixed(2)}{' '}
                      and{' '}
                                            {this.state.radioSelected === 'mm'
                                                ? (maxdiameterDriven / 0.03937).toFixed(2)
                                                : maxdiameterDriven.toFixed(2)}
                                        </div>
                                    </StyledCommon.FormLabel>
                                    <div className="field">
                                        <Field
                                            name="drivenShaftDiameter"
                                            type="text"
                                            component={renderInputCoupling}
                                            validate={[number, required]}
                                            onChange={this.handledrivenShaftDiameterChange}
                                        />
                                    </div>
                                </div>

                                {motorType}

                                {flywheel}

                                <StyledCommon.Button type="submit" className="btn">
                  Search
                                </StyledCommon.Button>
                            </form>
                        </div>
                    </StyledCommon.Container>
                </StyledCommon.Wrapper>
            </StyledCommon.BottomBarPb>
        );
    }
}

const mapStateToProps = state => ({
    response: state.content.pageDataTerms.data,
    maxSpeed: state.content.response,
    drivingDiameter: state.content.drivingDiamter,
    drivenDiameter: state.content.drivenDiamter,
    application: state.content.application,
    couplingUom: state.content.couplingUom,
    searchForm: state.content.getSearchFields,
    drivenEquipmentValue: state.content.drivenEquipment,
    getCouplingTypeValue: state.content.couplingTypeValue,
    initialValues: {
        ratedPower: state.content.getSearchFields ? state.content.getSearchFields.ratedPower : '',
        speed: state.content.getSearchFields ? state.content.getSearchFields.speed : '',
        drivenShaftDiameter:
      state.content.getSearchFields && state.content.getSearchFields.uomIn === 'mm'
          ? state.content.getSearchFields.drivenShaftDiameter / 0.03937
          : '' || state.content.getSearchFields
              ? state.content.getSearchFields.drivenShaftDiameter
              : '',
        application: state.content.getSearchFields ? state.content.getSearchFields.application : '',
        coupling: state.content.getSearchFields ? state.content.getSearchFields.coupling : '',
        engagementType: state.content.getSearchFields ? state.content.getSearchFields.engagementType : '',
        // drivingequipment: state.content.getSearchFields ? state.content.getSearchFields.drivingequipment : '',
        drivingequipment:
      state.content.getSearchFields &&
      localStorage.getItem('getCouplingTypeValue') === state.content.getSearchFields.couplingType
          ? state.content.getSearchFields.drivingequipment
          : '',

        // couplingType: state.content.getSearchFields ? state.content.getSearchFields.couplingType : '',
        couplingType:
      state.content.getSearchFields &&
      localStorage.getItem('getCouplingTypeValue') === state.content.getSearchFields.couplingType
          ? state.content.getSearchFields.couplingType
          : localStorage.getItem('getCouplingTypeValue')
              ? localStorage.getItem('getCouplingTypeValue')
              : '',

        uom: state.content.getSearchFields ? state.content.getSearchFields.uom : '',
        uomIn: state.content.getSearchFields ? state.content.getSearchFields.uomIn : '',
        drivingShaftDiameter:
      state.content.getSearchFields && state.content.getSearchFields.uomIn === 'mm'
          ? state.content.getSearchFields.drivingShaftDiameter / 0.03937
          : '' || state.content.getSearchFields
              ? state.content.getSearchFields.drivingShaftDiameter
              : '',
        drivenequipment: state.content.getSearchFields ? state.content.getSearchFields.drivenequipment : '',
        motorType:
      state.content.getSearchFields && state.content.getSearchFields.couplingType === 'PM'
          ? state.content.getSearchFields.motorType
          : '',
        motorSize:
      state.content.getSearchFields && state.content.getSearchFields.couplingType === 'PM'
          ? state.content.getSearchFields.motorSize
          : '',
        flywheel:
      state.content.getSearchFields && state.content.getSearchFields.engagementType === 'Standard SAE Flywheel to Shaft'
          ? state.content.getSearchFields.flywheel
          : '',
        nominaltorque: state.content.getSearchFields ? state.content.getSearchFields.nominaltorque : '',
        couplingSize: state.content.getSearchFields ? state.content.getSearchFields.couplingSize : ''
    }
});

export default connect(
    mapStateToProps,
    {
        searchCoupling,
        couplingInformation,
        getMaxSpeed,
        getDrivingDiameter,
        getDrivenDiameter,
        fetchApplicationList,
        getCouplingUom,
        getResultEmpty,
        getBrowseEmpty,
        getSearchFormCoupling,
        getDrivenEquipmentValue,
        couplingTypeChange
    }
)(
    reduxForm({
        enableReinitialize: true,
        form: 'searchcoupling'
    })(Search)
);
