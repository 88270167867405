// @flow

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './App.css';
import { PATHS } from './constants';
import Error404 from './pages/errors/404';
import Search from './pages/search';
import Quote from './pages/quote';
import Results from './pages/results';
import Browse from './pages/browse';

import Confirm from './pages/confirm';

// import Error404 from './errors/home';

/*
 TYPES
*/

type Props = {};

class App extends Component<Props> {
    render () {
        return (
            <Router>
                <div className="main-wrapper">
                    <Switch>
                        <Route exact path={PATHS.HOME} component={Search} />
                        <Route path={PATHS.QUOTE} component={Quote} />
                        <Route path={PATHS.RESULTS} component={Results} />
                        <Route path={PATHS.BROWSE} component={Browse} />
                        <Route path={PATHS.CONFIRM} component={Confirm} />
                        <Route component={Error404} />
                    </Switch>
                </div>
            </Router>
        );
    }
}

export default App;
