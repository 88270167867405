import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ReactImageFallback from 'react-image-fallback';

import * as StyledCommon from '../page.styled';
import { Header } from '../includes';
import { PATHS, IN_T0_MM, LBIN_TO_MNM, kNm } from '../../constants';
import { getCouplingDetail, getBrosweDetail, getCouplingUom, getQuoteActive } from '../../actions';

/*
 TYPES
*/

type Props = {
  getCouplingDetail: Function,
  getBrosweDetail: Function,
  getCouplingData: Object,
  activeSlide: Object,
  information: Object,
  response: Object,
  match: Object,
  history: Object,
  getCouplingUom: Function,
  couplingUom: Object,
  getQuoteActive: Function
};

class Browse extends Component<Props> {
    constructor (props) {
        super(props);
        this.state = {
            data: '',
            getUom: undefined
        };
        this.toMain = React.createRef();
    }

    componentDidMount () {
        this.props.getQuoteActive(false);
        this.props.getCouplingDetail(this.props.match.params.id);
        this.props.getCouplingUom();

        if (this.props.couplingUom.data) {
            if (this.props.couplingUom.data.setUom) {
                this.setState({ getUom: this.props.couplingUom.data.setUom });
            }
        }

        setTimeout(() => {
            this.toMain.current.scrollIntoView({ behavior: 'smooth' });
        }, 500);
    }

    clickHandle (id) {
        this.props.getBrosweDetail(id);
        this.props.getQuoteActive(true);
        this.props.history.push(PATHS.QUOTE);
    }

    renderList () {
        if (this.props.response.data) {
            let i = 1;
            return this.props.response.data.data.map(stream => (
                <div key={i++}>
                    <ReactImageFallback
                        src={PATHS.IMAGEPATH + `/${stream.couplingImage}`}
                        fallbackImage="noimage.png"
                        alt="Renold"
                        className="img-thumb"
                    />

                    <div style={{ textAlign: 'left', marginBottom: '30px' }}>
                        <h5 className="center">
                            {' '}
                            <strong> {stream.couplingName} </strong>
                        </h5>
                    </div>

                    <StyledCommon.Table className="table">
                        <thead>
                            <tr>
                                <th colSpan="3">Information</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Engagement Type</td>
                                <td>{stream.engagementType}</td>
                                <td />
                            </tr>
                            <tr>
                                <td>Max Speed</td>
                                <td>{stream.maxSpeed}</td>
                                <td>rpm</td>
                            </tr>
                            <tr>
                                <td>Max Torque</td>
                                <td>
                                    {this.props.information.maxtorqueuom == 'lb-ft'
                                        ? stream.maxTorque
                                        : (stream.maxTorque / kNm).toFixed(3)}
                                </td>
                                <td>{this.props.information.maxtorqueuom}</td>
                            </tr>
                            <tr>
                                <td>Nominal Torque</td>
                                <td>
                                    {this.props.information.maxtorqueuom == 'lb-ft'
                                        ? stream.nominalTorque
                                        : (stream.nominalTorque / kNm).toFixed(3)}
                                </td>
                                <td>{this.props.information.maxtorqueuom}</td>
                            </tr>
                        </tbody>
                    </StyledCommon.Table>

                    <StyledCommon.Table className="table">
                        <thead>
                            <tr>
                                <th colSpan="3">Standard Dimensions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Standard Rubber Block</td>
                                <td>{stream.standardRubberBlock}</td>
                                <td />
                            </tr>
                            <tr>
                                <td>Max Outside Diameter (OD)</td>
                                <td>{this.props.information.setUom == 'in' ? stream.maxOD : (stream.maxOD / IN_T0_MM).toFixed(2)}</td>
                                <td>{this.props.information.setUom}</td>
                            </tr>
                            <tr>
                                <td>Standard Distance between shaft ends</td>
                                <td>
                                    {this.props.information.setUom == 'in'
                                        ? stream.standardDistanceBetween
                                        : (stream.standardDistanceBetween / IN_T0_MM).toFixed(2)}
                                </td>
                                <td>{this.props.information.setUom}</td>
                            </tr>
                            <tr>
                                <td>Min Drive Flange Bore</td>
                                <td>
                                    {this.props.information.setUom == 'in'
                                        ? stream.minDriveFlangeBore
                                        : (stream.minDriveFlangeBore / IN_T0_MM).toFixed(2)}
                                </td>
                                <td>{this.props.information.setUom}</td>
                            </tr>
                            <tr>
                                <td> Max Drive Flange Bore</td>
                                <td>
                                    {this.props.information.setUom == 'in'
                                        ? stream.maxDriveFlangeBore
                                        : (stream.maxDriveFlangeBore / IN_T0_MM).toFixed(2)}
                                </td>
                                <td>{this.props.information.setUom}</td>
                            </tr>
                            <tr>
                                <td>Min Driven Flange Bore</td>
                                <td>
                                    {this.props.information.setUom == 'in'
                                        ? stream.minDrivenFlangeBore
                                        : (stream.minDrivenFlangeBore / IN_T0_MM).toFixed(2)}
                                </td>
                                <td>{this.props.information.setUom}</td>
                            </tr>
                            <tr>
                                <td>Max Driven Flange Bore</td>
                                <td>
                                    {this.props.information.setUom == 'in'
                                        ? stream.maxDrivenFlangeBore
                                        : (stream.maxDrivenFlangeBore / IN_T0_MM).toFixed(2)}
                                </td>
                                <td>{this.props.information.setUom}</td>
                            </tr>
                        </tbody>
                    </StyledCommon.Table>

                    <StyledCommon.Table className="table">
                        <thead>
                            <tr>
                                <th colSpan="3">Standard Misalignment</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Conical</td>
                                <td>{stream.misalignmentConical}</td>
                                <td>(degree)</td>
                            </tr>
                            <tr>
                                <td>Axial</td>
                                <td>
                                    {this.props.information.setUom == 'in'
                                        ? stream.misalignmentAxial
                                        : (stream.misalignmentAxial / IN_T0_MM).toFixed(2)}
                                </td>
                                <td>{this.props.information.setUom}</td>
                            </tr>
                            <tr>
                                <td>Radial</td>
                                <td>
                                    {this.props.information.setUom == 'in'
                                        ? stream.misalignmentRadial
                                        : (stream.misalignmentRadial / IN_T0_MM).toFixed(2)}
                                </td>
                                <td>{this.props.information.setUom}</td>
                            </tr>
                        </tbody>
                    </StyledCommon.Table>

                    <StyledCommon.Table className="table">
                        <thead>
                            <tr>
                                <th colSpan="3">Standard Block Dynamic Torsional Stiffness</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>@.25 TKN</td>

                                <td>
                                    {this.props.information.setUom == 'in'
                                        ? stream.standardBlockStiffness25x
                                        : (stream.standardBlockStiffness25x / LBIN_TO_MNM).toFixed(4)}
                                </td>
                                <td>{this.props.information.setUom == 'in' ? 'x10^6 lb-in/rad' : 'MNm/rad'}</td>
                            </tr>
                            <tr>
                                <td>@.5 TKN</td>

                                <td>
                                    {this.props.information.setUom == 'in'
                                        ? stream.standardBlockStiffness5x
                                        : (stream.standardBlockStiffness5x / LBIN_TO_MNM).toFixed(4)}
                                </td>
                                <td>{this.props.information.setUom == 'in' ? 'x10^6 lb-in/rad' : 'MNm/rad'}</td>
                            </tr>
                            <tr>
                                <td>@.75 TKN</td>
                                <td>
                                    {this.props.information.setUom == 'in'
                                        ? stream.standardBlockStiffness75x
                                        : (stream.standardBlockStiffness75x / LBIN_TO_MNM).toFixed(4)}
                                </td>
                                <td>{this.props.information.setUom == 'in' ? 'x10^6 lb-in/rad' : 'MNm/rad'}</td>
                            </tr>
                            <tr>
                                <td>@1 TKN</td>
                                <td>
                                    {this.props.information.setUom == 'in'
                                        ? stream.standardBlockStiffness1x
                                        : (stream.standardBlockStiffness1x / LBIN_TO_MNM).toFixed(4)}
                                </td>
                                <td>{this.props.information.setUom == 'in' ? 'x10^6 lb-in/rad' : 'MNm/rad'}</td>
                            </tr>
                        </tbody>
                    </StyledCommon.Table>

                    <StyledCommon.BrowseLink to="#" data-id={stream._id} onClick={() => this.clickHandle(stream._id)}>
                        {' '}
            Quote Builder{' '}
                    </StyledCommon.BrowseLink>
                </div>
            ));
        }
    }
    render () {
        return (
            <StyledCommon.BottomBarPb ref={this.toMain}>
                <StyledCommon.Wrapper>
                    <Header />
                    <StyledCommon.Container>
                        <Carousel
                            showArrows={true}
                            selectedItem={this.props.activeSlide}
                            showIndicators={false}
                            autoPlay={false}
                            swipeable={true}
                            showThumbs={false}
                        >
                            {this.renderList()}
                        </Carousel>
                        <div />
                    </StyledCommon.Container>
                </StyledCommon.Wrapper>
            </StyledCommon.BottomBarPb>
        );
    }
}

const mapStateToProps = state => ({
    response: state.content.pageDataPrivacy,
    getCouplingData: state.content.pageCouplingData,
    information: state.content.couplingInformation,
    activeSlide: state.content.activeSlide,
    couplingUom: state.content.couplingUom
});

export default connect(
    mapStateToProps,
    { getCouplingDetail, getBrosweDetail, getCouplingUom, getQuoteActive }
)(Browse);
