import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as Styled from '../pages/page.styled';

type Props = {
  input: Object,
  label: string,
  type: string,
  meta: Object,
  showTime: string,
  children: Object,
  data: string,
  placeholder: string,
  onClick: Function,
  value: string
};

const renderInputHidden = ({ input, label, type, meta: { touched, error } }: Props) => (
    <div className="rad">
        <Styled.FormInput {...input} type={type} autoComplete="off" className="form-control hidden" placeholder={label} />
        {touched && (error && <Styled.error>{error}</Styled.error>)}
    </div>
);

const renderInputField = ({ input, data, type, meta: { touched, error } }: Props) => (
    <div>
        <Styled.FormInput {...input} value={data} type={type} autoComplete="off" className="form-control" />
        {touched && (error && <Styled.error>{error}</Styled.error>)}
    </div>
);

const renderInput = ({ input, label, data, type, meta: { touched, error } }: Props) => (
    <div className="mb-3 formDiv">
        {label && (label && <Styled.FormLabel>{label}</Styled.FormLabel>)}
        <div className="field">
            <Styled.FormInput {...input} value={data} type={type} autoComplete="off" className="form-control" />
            {touched && (error && <Styled.error>{error}</Styled.error>)}
        </div>
    </div>
);

// const renderInput = ({ placeholder, id, input,data, type, meta: { touched, error } }: Props) => {
//   return (
//     <div>
//       <input  {...input} type={type} value={data} placeholder={placeholder} id={id} className="form-control" />
//       {touched && (error && <Styled.error>{error}</Styled.error>)}
//     </div>
//   );
// }

const renderInputCoupling = ({ input, type, meta: { touched, error } }: Props) => (
    <div>
        <Styled.FormInput {...input} type={type} autoComplete="off" className="form-control" />
        {touched && (error && <Styled.error>{error}</Styled.error>)}
    </div>
);

const renderSelectField = ({ input, meta: { touched, error }, children }: Props) => (
    <div>
        <Styled.FormSelect {...input} className="form-control">
            {children}
        </Styled.FormSelect>
        {touched && (error && <Styled.error>{error}</Styled.error>)}
    </div>
);

const renderSelectCode = ({ input, meta: { touched, error }, children }: Props) => (
    <div className="countrycode">
        <Styled.FormSelect {...input} className="form-control">
            {children}
        </Styled.FormSelect>
        {touched && (error && <Styled.error>{error}</Styled.error>)}
    </div>
);

const SelectField = ({ input, label, meta: { touched, error }, children }: Props) => (
    <div className="mb-3 formDiv">
        {label && (label && <Styled.FormLabel>{label}</Styled.FormLabel>)}
        <div className="field">
            <Styled.FormSelect {...input} className="form-control">
                {children}
            </Styled.FormSelect>
            {touched && (error && <Styled.error>{error}</Styled.error>)}
        </div>
    </div>
);

const requiredRenderInput = ({ input, label, data, type, meta: { touched, error } }: Props) => (
    <div className="mb-3 formDiv">
        {label &&
      (label && (
          <Styled.FormLabel>
              {label} <em className="required">*</em>
          </Styled.FormLabel>
      ))}
        <div className="field">
            <Styled.FormInput {...input} value={data} type={type} autoComplete="off" className="form-control" />
            {touched && (error && <Styled.error>{error}</Styled.error>)}
        </div>
    </div>
);

const requiredSelectField = ({ input, label, meta: { touched, error }, children }: Props) => (
    <div className="mb-3 formDiv">
        {label &&
      (label && (
          <Styled.FormLabel>
              {label} <em className="required">*</em>
          </Styled.FormLabel>
      ))}
        <div className="field">
            <Styled.FormSelect {...input} className="form-control">
                {children}
            </Styled.FormSelect>
            {touched && (error && <Styled.error>{error}</Styled.error>)}
        </div>
    </div>
);

const renderInput1 = ({ input, type, meta: { touched, error } }: Props) => (
    <Styled.Field1 className="field">
        <Styled.FormInput {...input} type={type} autoComplete="off" className="form-control" />
        {touched && (error && <Styled.error>{error}</Styled.error>)}
    </Styled.Field1>
);

const renderInputMobile = ({ input, type, meta: { touched, error } }: Props) => (
    <Styled.FieldMobile>
        <Styled.FormInput {...input} type={type} autoComplete="off" className="form-control" />
        {touched && (error && <Styled.error>{error}</Styled.error>)}
    </Styled.FieldMobile>
);

const SelectField1 = ({ input, meta: { touched, error }, children }: Props) => (
    <Styled.Field className="field">
        <Styled.FormSelect {...input} className="form-control">
            {children}
        </Styled.FormSelect>
        {touched && (error && <Styled.error>{error}</Styled.error>)}
    </Styled.Field>
);

const SelectFieldUom = ({ input, meta: { touched, error }, children }: Props) => (
    <Styled.Field className="field">
        <Styled.FormSelect {...input} className="form-control">
            {children}
        </Styled.FormSelect>
        {touched && (error && <Styled.error>{error}</Styled.error>)}
    </Styled.Field>
);

const renderTextArea = ({ input, label, placeholder, meta: { touched, error } }: Props) => (
    <div className="mb-3 formDiv">
        {label && (label && <Styled.FormLabel>{label}</Styled.FormLabel>)}
        <div className="field">
            <textarea {...input} className="form-control" placeholder={placeholder} rows="3" cols="40" />
            {touched && (error && <Styled.error>{error}</Styled.error>)}
        </div>
    </div>
);

const renderDatePicker = ({ label, input: { onChange, value }, meta: { touched, error } }: Props) => {
    let previousDate = new Date();
    previousDate.setMonth(previousDate.getMonth() - 0);
    return (
        <div className="mb-3 formDiv">
            {label &&
        (label && (
            <Styled.FormLabel>
                {label} <em className="required">*</em>
            </Styled.FormLabel>
        ))}

            <div className="field">
                <DatePicker
                    customInput={<CustomInputForAccident />}
                    name={'from'}
                    selected={!value ? null : new Date(value)}
                    minDate={previousDate}
                    onChange={onChange}
                    dateFormat="dd/MM/yyyy"
                />
                {touched && (error && <Styled.error>{error}</Styled.error>)}
            </div>
        </div>
    );
};

const CustomInputForAccident = forwardRef((props: Props, ref) => (
    <input
        ref={ref}
        className="datepicker-height form-control"
        onClick={props.onClick}
        value={props.value}
        type="text"
        readOnly={true}
    />
));

CustomInputForAccident.displayName = 'CustomInputForAccident';

export {
    renderInput,
    SelectField,
    renderInput1,
    SelectField1,
    renderInputCoupling,
    renderDatePicker,
    renderTextArea,
    renderSelectField,
    renderSelectCode,
    renderInputMobile,
    requiredSelectField,
    requiredRenderInput,
    SelectFieldUom,
    renderInputHidden,
    renderInputField
};
