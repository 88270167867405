// @flow
import { API_BASE_PATH, SERVERPATH } from '.././config/config';

export const DEFAULT_LANGUAGE = 'en-us';
export const DEFAULT_COUNTRY_CODE = 'us';
export const SUBMODULE_NAME = 'vehicle-lease';

export const PATHS = {
    HOME: '/',
    SEARCH: '/search',
    QUOTE: '/quote',
    RESULTS: '/results',
    BROWSE: '/browse',
    CONFIRM: '/confirm',
    APIPATH: API_BASE_PATH,
    IMAGEPATH: `${SERVERPATH}uploads/couplings`,
    // TODO: Should go to Ipifny's 404 page by using command.navigateTo(PAGE_NOT_FOUND)
    PAGE_NOT_FOUND: '/errors/404'
};

export const IN_T0_MM = 0.03937;
export const LBIN_TO_MNM = 8.840746;
export const kNm = 737.56214927727;
export const lb_ft = 0.001355817605167;

export const API_PATHS = {
    SEARCH_COUPLING: `${API_BASE_PATH}admin/coupling/search`,
    FETCH_SEARCH_COUPLING_API: `${API_BASE_PATH}admin/coupling/get-all`,
    GET_COUPLING_RECORD_API: `${API_BASE_PATH}admin/coupling/get-one`,
    GET_MAX_SPEED: `${API_BASE_PATH}admin/coupling/max-speed`,
    GET_DIAMETER: `${API_BASE_PATH}admin/coupling/driving-shaft-diameter`,
    GET_DRIVEN: `${API_BASE_PATH}admin/coupling/driven-shaft-diameter`,
    SUBMIT_QUOTE_API: `${API_BASE_PATH}admin/coupling/send-coupling-information`,
    APPLICATION_API: `${API_BASE_PATH}admin/application/get-all`,
    SIMPLE_QUOTE_API: `${API_BASE_PATH}admin/coupling/send-quote`,
    GET_COUPLING_UOM_API: `${API_BASE_PATH}admin/setting/get-uom`
};

// NOTE: Make sure the event keys are exactly the same as they are in the shell:
// https://bitbucket.org/accordogroup/csa.frontend/src/master/src/common/constants.js
export const MF_COMMAND_EVENTS = {
    ERROR_404: 'ERROR_404'
};
