import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import * as StyledCommon from '../page.styled';
import { Header } from '../includes';
import {
    renderInput,
    SelectField,
    renderTextArea,
    renderInputCoupling,
    renderSelectField,
    renderSelectCode,
    renderInputMobile,
    renderDatePicker,
    requiredRenderInput,
    requiredSelectField,
    renderInputField
} from '../../constants/redux-form';
import { submitQuoteForm, SimpleQuoteForm, getCouplingUom, dataUpdate } from '../../actions';
import {
    required,
    maxLength64,
    maxLength36,
    maxLength20,
    email,
    number,
    onlyChar,
    maxLength13,
    nondecimal,
    customerAddress,
    charVal
} from '../../constants/validations';

import {
    EXISTCUSTOMER,
    DESIREDDRIVE,
    DRIVEFLANGE,
    PHSIZE,
    PHQUANTITY,
    LHSIZE,
    LHQUANTITY,
    BGRADE,
    BSPEED,
    INDUSTRY,
    COUNTRYCODE
} from './options';
import { PATHS, IN_T0_MM } from '../../constants';

/*
 TYPES
*/

type Props = {
  handleSubmit: Function,
  submitQuoteForm: Function,
  getCouplingUom: Function,
  response: Object,
  browseData: Object,
  SimpleQuoteForm: Function,
  information: Object,
  history: Object,
  couplingUom: Object,
  initialValues: Object
};

let currentDate = new Date();

class Quote extends Component<Props> {
    componentWillReceiveProps (nextProps) {
        if (nextProps.browseData) {
            this.setState({
                standardDistanceBetween:
          this.props.information.setUom == 'in'
              ? nextProps.browseData.standardDistanceBetween
              : (nextProps.browseData.standardDistanceBetween / IN_T0_MM).toFixed(2),
                maxDriveFlangeLength:
          this.props.information.setUom == 'in'
              ? nextProps.browseData.maxDriveFlangeLength
              : (nextProps.browseData.maxDriveFlangeLength / IN_T0_MM).toFixed(2),
                maxDrivenFlangeLength:
          this.props.information.setUom == 'in'
              ? nextProps.browseData.maxDrivenFlangeLength
              : (nextProps.browseData.maxDrivenFlangeLength / IN_T0_MM).toFixed(2),
                misalignmentAxial:
          this.props.information.setUom == 'in'
              ? nextProps.browseData.misalignmentAxial
              : (nextProps.browseData.misalignmentAxial / IN_T0_MM).toFixed(2),

                misalignmentRadial:
          this.props.information.setUom == 'in'
              ? nextProps.browseData.misalignmentRadial
              : (nextProps.browseData.misalignmentRadial / IN_T0_MM).toFixed(2),
                misalignmentConical: nextProps.browseData.misalignmentConical
            });
        }
        this.updateState();
    }

    updateState () {
        currentDate = new Date();
    }

    constructor (props) {
        super(props);
        this.state = {
            data: [],
            formData: [],
            balancing: '',
            existCustomer: '',
            setScrews: '',
            lifingHoles: '',
            date: currentDate,
            pullerHoles: '',
            misalignmentKnown: '',
            misalignmentConical: '',
            drivenFlangeKeyway: '',
            drivenShaftLengthknown: '',
            driveFlangeKeyway: '',
            drivingShaftLengthknown: '',
            standardDistance: '',
            standardDistanceBetween: '',
            misalignmentAxial: '',
            radioSelected: 0
        };
        this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
        this.toMain = React.createRef();
        this.onInputChange = this.onInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange (evt) {
        this.setState({ [evt.target.name]: evt.target.value });
    }

    onSubmit = formValues => {
        if (typeof this.props.browseData === 'undefined' || this.props.browseData === '') {
            this.props.SimpleQuoteForm(formValues);
        } else {
            if (this.props.information) {
                this.state.formData.push({ name: 'Coupling_Information', value: this.props.information });
            }

            if (this.props.browseData) {
                this.state.formData.push({ name: 'Browse_Data', value: this.props.browseData });
            }

            this.state.formData.push({ name: 'Quote_detail', value: formValues });
            this.props.submitQuoteForm(this.state.formData);
        }
        // return false;

        localStorage.setItem('confirmPage', 'yes');
        this.props.history.push(PATHS.CONFIRM);
    };

    renderList () {
        if (!this.props.browseData) {
            return <h1 className="page-heading">Please fill the below details for consultation</h1>;
        } else {
            return <h1 className="page-heading">{this.props.browseData.couplingName}</h1>;
        }
    }

    componentDidMount () {
        this.props.getCouplingUom();

        if (this.props.couplingUom.data) {
            if (this.props.couplingUom.data.setUom) {
                this.setState({ getUom: this.props.couplingUom.data.setUom });
            }
        }

        if (this.props.initialValues.uomIn === 'mm') {
            this.setState({ radioSelected: 'mm' });
        }
        if (this.props.initialValues.uomIn === 'in') {
            this.setState({ radioSelected: 'in' });
        }
        setTimeout(() => {
            this.toMain.current.scrollIntoView({ behavior: 'smooth' });
        }, 500);
    }

    onRadioBtnClick (radioSelected) {
        this.setState({
            radioSelected: radioSelected
        });
    }

    onInputChange (e) {
        var name = e.target.name;
        this.setState({ [name]: e.target.value });
    }

    renderDistanceBwShaftEnds () {
        const { setUom } = this.props.information;

        if (this.props.browseData.engagementType === 'Shaft to Shaft' && this.state.standardDistance === 'No') {
            return (
                <div className="mb-3 formDiv">
                    <StyledCommon.FormLabel>Distance Between Shaft ({setUom})</StyledCommon.FormLabel>
                    <div className="field">
                        <Field
                            name="standardDistanceBetween"
                            type="text"
                            component={renderInputCoupling}
                            label="Distance Between Shaft Ends"
                            data={this.state.standardDistanceBetween}
                            onChange={this.onInputChange}
                            validate={number}
                        />
                    </div>
                </div>
            // <div className="mb-2 maindiv">
            //     <StyledCommon.FormLabel>Distance Between Shaft ({setUom})</StyledCommon.FormLabel>

            //     <StyledCommon.TwoField>
            //         <Field
            //             name="standardDistanceBetween"
            //             type="text"
            //             onChange={this.onInputChange}
            //             data={this.state.standardDistanceBetween}
            //             label="Distance Between Shaft Ends"
            //             component={renderInput1}
            //             validate={number}
            //         />

            //        <Field name="standarduom" component={SelectField1}>
            //             {UnitOfMeasurement.map(function (data, key) {
            //                 return (
            //                     <option key={key} value={data.key} selected={setUom === data.value ? true : ''}>
            //                         {data.value}
            //                     </option>
            //                 );
            //             })}
            //         </Field>
            //     </StyledCommon.TwoField>
            // </div>
            );
        }
    }

    renderDrivingShaftLengthknown () {
        if (
            this.props.browseData.coupling === 'Full Coupling' ||
      this.props.browseData.coupling === 'Half Coupling – Rigid'
        ) {
            return (
                <div>
                    <Field
                        name="drivingShaftLengthknown"
                        label="Driving Shaft Length known?"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                        validate={required}
                    >
                        {EXISTCUSTOMER.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>
                </div>
            );
        }
    }

    renderDrivingShaftLength () {
        const { setUom } = this.props.information;

        if (this.state.drivingShaftLengthknown === 'Yes') {
            return (
                <div className="mb-3 formDiv">
                    <StyledCommon.FormLabel>Driving Shaft Length ({setUom})</StyledCommon.FormLabel>
                    <div className="field">
                        <Field
                            name="maxDriveFlangeLength"
                            type="text"
                            component={renderInputCoupling}
                            label="Driving Shaft Length"
                            data={this.state.maxDriveFlangeLength}
                            onChange={this.onInputChange}
                            validate={number}
                        />
                    </div>
                </div>
            // <div className="mb-2 maindiv">
            //     <StyledCommon.FormLabel>Driving Shaft Length ({setUom})</StyledCommon.FormLabel>
            //     <StyledCommon.TwoField>
            //         <Field
            //             name="maxDriveFlangeLength"
            //             label="Driving Shaft Length"
            //             component={renderInput1}
            //             type="text"
            //             onChange={this.onInputChange}
            //             data={this.state.maxDriveFlangeLength}
            //             validate={number}
            //         />
            //         <Field name="uom" component={SelectField1}>
            //             {UnitOfMeasurement.map(function (data, key) {
            //                 return (
            //                     <option key={key} value={data.key} selected={setUom === data.value ? true : ''}>
            //                         {data.value}
            //                     </option>
            //                 );
            //             })}
            //         </Field>
            //     </StyledCommon.TwoField>
            // </div>
            );
        }
    }

    renderDriveFlangeKeywaySize () {
        const { setUom } = this.props.information;

        if (this.state.driveFlangeKeyway === 'Yes') {
            return (
                <div className="mb-3 formDiv">
                    <StyledCommon.FormLabel>
            Drive Flange Keyway Size ({setUom})<em className="required">*</em>
                    </StyledCommon.FormLabel>
                    <div className="field">
                        <Field
                            name="driveFlangeKeywaySize"
                            type="text"
                            component={renderInputCoupling}
                            label="Drive Flange Keyway Size"
                            onChange={this.handleChange}
                            validate={[required, number]}
                        />
                    </div>
                </div>
            // <div className="mb-2 maindiv">
            //     <StyledCommon.FormLabel>
            //         Drive Flange Keyway Size ({setUom})<em className="required">*</em>
            //     </StyledCommon.FormLabel>
            //     <StyledCommon.TwoField>
            //         <Field
            //             name="driveFlangeKeywaySize"
            //             label="Drive Flange Keyway Size"
            //             type="text"
            //             component={renderInput1}
            //             onChange={this.handleChange}
            //             validate={[required, number]}
            //         />
            //          <Field name="driveFlangeKeywaySizeUom" validate={[required]} component={SelectField1}>
            //             {UnitOfMeasurement.map(function (data, key) {
            //                 return (
            //                     <option key={key} value={data.key} selected={setUom === data.value ? true : ''}>
            //                         {data.value}
            //                     </option>
            //                 );
            //             })}
            //         </Field>
            //     </StyledCommon.TwoField>
            // </div>
            );
        }
    }

    renderDrivenShaftLength () {
        const { setUom } = this.props.information;
        if (this.state.drivenShaftLengthknown === 'Yes') {
            return (
                <div className="mb-3 formDiv">
                    <StyledCommon.FormLabel>Driven Shaft Length ({setUom})</StyledCommon.FormLabel>
                    <div className="field">
                        <Field
                            name="maxDrivenFlangeLength"
                            type="text"
                            component={renderInputCoupling}
                            label="Driven Shaft Length"
                            onChange={this.onInputChange}
                            validate={number}
                            data={
                                this.props.information.setUom == 'in'
                                    ? this.state.maxDrivenFlangeLength
                                    : (this.state.maxDrivenFlangeLength / IN_T0_MM).toFixed(2)
                            }
                        />
                    </div>
                </div>

            // <div className="mb-2 maindiv">
            //     <StyledCommon.FormLabel>Driven Shaft Length ({setUom})</StyledCommon.FormLabel>
            //     <StyledCommon.TwoField>
            //         <Field
            //             name="maxDrivenFlangeLength"
            //             label="Driven Shaft Length"
            //             component={renderInput1}
            //             onChange={this.onInputChange}
            //             validate={number}
            //             type="text"
            //             data={
            //                 this.props.information.setUom == 'in'
            //                     ? this.state.maxDrivenFlangeLength
            //                     : (this.state.maxDrivenFlangeLength / IN_T0_MM).toFixed(2)
            //             }
            //         />
            //          <Field name="uom" component={SelectField1}>
            //             {UnitOfMeasurement.map(function (data, key) {
            //                 return (
            //                     <option key={key} value={data.key} selected={setUom === data.value ? true : ''}>
            //                         {data.value}
            //                     </option>
            //                 );
            //             })}
            //         </Field>
            //     </StyledCommon.TwoField>
            // </div>
            );
        }
    }

    renderDrivenFlangeKeywaySize () {
        const { setUom } = this.props.information;
        if (this.state.drivenFlangeKeyway === 'Yes') {
            return (
                <div className="mb-3 formDiv">
                    <StyledCommon.FormLabel>
            Driven Flange Keyway Size ({setUom})<em className="required">*</em>
                    </StyledCommon.FormLabel>
                    <div className="field">
                        <Field
                            name="maxDrivenFlangeKeywaySize"
                            label="Driven Flange Keyway Size"
                            type="text"
                            component={renderInputCoupling}
                            onChange={this.onInputChange}
                            validate={[number, required]}
                        />
                    </div>
                </div>

            // <div className="mb-2 maindiv">
            //     <StyledCommon.FormLabel>
            //         Driven Flange Keyway Size ({setUom})<em className="required">*</em>
            //     </StyledCommon.FormLabel>
            //     <StyledCommon.TwoField>
            //         <Field
            //             name="maxDrivenFlangeKeywaySize"
            //             label="Driven Flange Keyway Size"
            //             component={renderInput1}
            //             onChange={this.onInputChange}
            //             type="text"
            //             validate={number}
            //         />
            //         <Field name="maxDrivenFlangeKeywaySizeUom" validate={[required]} component={SelectField1}>
            //             {UnitOfMeasurement.map(function (data, key) {
            //                 return (
            //                     <option key={key} value={data.key} selected={setUom === data.value ? true : ''}>
            //                         {data.value}
            //                     </option>
            //                 );
            //             })}
            //         </Field>
            //     </StyledCommon.TwoField>
            // </div>
            );
        }
    }

    renderMisalignment () {
        const { setUom } = this.props.information;
        if (this.state.misalignmentKnown === 'Yes') {
            return (
                <div>
                    <div className="mb-3 formDiv">
                        <StyledCommon.FormLabel>Misalignment Conical (degree)</StyledCommon.FormLabel>
                        <div className="field">
                            <Field
                                name="misalignmentConical"
                                label="Misalignment Conical"
                                component={renderInputField}
                                type="text"
                                onChange={this.onInputChange}
                                data={this.state.misalignmentConical}
                                validate={number}
                            />
                        </div>
                    </div>

                    <div className="mb-3 formDiv">
                        <StyledCommon.FormLabel>Misalignment Axial ({setUom})</StyledCommon.FormLabel>
                        <div className="field">
                            <Field
                                name="misalignmentAxial"
                                label="Misalignment Axial"
                                component={renderInputField}
                                type="text"
                                onChange={this.onInputChange}
                                data={this.state.misalignmentAxial}
                                validate={number}
                            />
                        </div>
                    </div>

                    <div className="mb-3 formDiv">
                        <StyledCommon.FormLabel>Misalignment Radial ({setUom})</StyledCommon.FormLabel>
                        <div className="field">
                            <Field
                                name="misalignmentRadial"
                                label="Misalignment Radial"
                                component={renderInputField}
                                onChange={this.onInputChange}
                                type="text"
                                data={this.state.misalignmentRadial}
                                validate={number}
                            />
                        </div>
                    </div>
                </div>
            );
        }
    }

    renderPullerHoles () {
        if (this.state.pullerHoles === 'Yes') {
            return (
                <div>
                    <Field
                        name="pullerHoleSize"
                        label="Puller Hole Size"
                        validate={required}
                        component={requiredSelectField}
                        onChange={this.handleChange}
                    >
                        {PHSIZE.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    {this.renderPullerSize()}

                    <Field
                        name="pullerHoleQuantity"
                        label="Puller Hole Quantity"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                        validate={required}
                    >
                        {PHQUANTITY.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>
                    {this.renderPullerQuantity()}
                </div>
            );
        }
    }

    renderPullerQuantity () {
        if (this.state.pullerHoleQuantity === 'Use Custom') {
            return (
                <div>
                    <Field
                        name="pullerQuantity"
                        label="Puller Quantity"
                        component={requiredRenderInput}
                        validate={required}
                        onChange={this.onInputChange}
                        type="text"
                    />
                </div>
            );
        }
    }

    renderPullerSize () {
        if (this.state.pullerHoleSize === 'Use Custom') {
            return (
                <div>
                    <Field
                        name="pullerSize"
                        label="Puller Size"
                        component={requiredRenderInput}
                        validate={required}
                        onChange={this.onInputChange}
                        type="text"
                    />
                </div>
            );
        }
    }

    renderLiftingQuantity () {
        if (this.state.liftingHoleQuantity === 'Use Custom') {
            return (
                <div>
                    <Field
                        name="liftingQuantity"
                        label="Lifting Quantity"
                        component={requiredRenderInput}
                        validate={required}
                        onChange={this.onInputChange}
                        type="text"
                    />
                </div>
            );
        }
    }

    renderLiftingSize () {
        if (this.state.liftingHoleSize === 'Use Custom') {
            return (
                <div>
                    <Field
                        name="liftingSize"
                        label="Lifting Size"
                        component={requiredRenderInput}
                        validate={required}
                        onChange={this.onInputChange}
                        type="text"
                    />
                </div>
            );
        }
    }

    renderLifingHoles () {
        if (this.state.lifingHoles === 'Yes') {
            return (
                <div>
                    <Field
                        name="liftingHoleSize"
                        validate={required}
                        label="Lifting Hole Size"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                    >
                        {LHSIZE.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>
                    {this.renderLiftingSize()}

                    <Field
                        name="liftingHoleQuantity"
                        label="Lifting Hole Quantity"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                        validate={required}
                    >
                        {LHQUANTITY.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>
                    {this.renderLiftingQuantity()}
                </div>
            );
        }
    }

    renderScrewSize () {
        const { setUom } = this.props.information;

        if (this.state.setScrewSize === 'Use Custom') {
            return (
                <div className="mb-3 formDiv">
                    <StyledCommon.FormLabel>
            Screw Size ({setUom})<em className="required">*</em>
                    </StyledCommon.FormLabel>
                    <div className="field">
                        <Field
                            name="screwSize"
                            label="Screw Size"
                            type="text"
                            component={renderInputCoupling}
                            onChange={this.onInputChange}
                            validate={required}
                        />
                    </div>
                </div>

            // <div className="mb-2 maindiv">
            //     <StyledCommon.FormLabel>
            //         Screw Size ({setUom})<em className="required">*</em>
            //     </StyledCommon.FormLabel>
            //     <StyledCommon.TwoField>
            //         <Field
            //             name="screwSize"
            //             label="Screw Size"
            //             component={renderInput1}
            //             validate={required}
            //             onChange={this.onInputChange}
            //             type="text"
            //         />
            //          <Field name="screwSizeUom" validate={[required]} component={SelectField1}>
            //             {UnitOfMeasurement.map(function (data, key) {
            //                 return (
            //                     <option key={key} value={data.key} selected={setUom === data.value ? true : ''}>
            //                         {data.value}
            //                     </option>
            //                 );
            //             })}
            //         </Field>
            //     </StyledCommon.TwoField>
            // </div>
            );
        }
    }

    renderSetScrews () {
        if (this.state.setScrews === 'Yes') {
            return (
                <div>
                    <Field
                        name="setScrewSize"
                        validate={required}
                        label="Set Screw Size"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                    >
                        {LHSIZE.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>
                    {this.renderScrewSize()}
                </div>
            );
        }
    }

    renderSpeed () {
        if (this.state.balancingSpeed === 'Use Custom') {
            return (
                <div>
                    <Field
                        name="speed"
                        label="Custom Balancing Speed (rpm)"
                        component={requiredRenderInput}
                        validate={[required, number, nondecimal]}
                        onChange={this.onInputChange}
                        type="text"
                    />
                </div>
            );
        }
    }

    renderBalancing () {
        if (this.state.balancing === 'Yes') {
            return (
                <div>
                    <Field
                        name="balancingGrade"
                        validate={required}
                        label="Balancing Grade"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                    >
                        {BGRADE.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    <Field
                        name="balancingSpeed"
                        validate={required}
                        label="Balancing Speed"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                    >
                        {BSPEED.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>
                    {this.renderSpeed()}
                </div>
            );
        }
    }

    renderFullCoupling () {
        if (this.props.browseData && this.props.browseData.coupling === 'Full Coupling') {
            return (
                <div className="coupling-quote">
                    <b className="coupling-quote-heading">Quote Full Coupling Product Questions</b>
                    <Field
                        name="standardDistance"
                        label="Standard distance between shaft ends will be applied unless otherwise specificed, do you accept?"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                        validate={required}
                    >
                        {EXISTCUSTOMER.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    {this.renderDistanceBwShaftEnds()}

                    {this.renderDrivingShaftLengthknown()}

                    {this.renderDrivingShaftLength()}

                    <Field
                        name="desiredDriveFlangeBore"
                        label="Desired Drive Flange Bore/Shaft Fit"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                        validate={required}
                    >
                        {DESIREDDRIVE.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    <Field
                        name="driveFlangeKeyway"
                        label="Drive Flange Keyway Required?"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                        validate={required}
                    >
                        {DRIVEFLANGE.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    {this.renderDriveFlangeKeywaySize()}

                    <Field
                        name="drivenShaftLengthknown"
                        label="Driven Shaft Length known?"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                        validate={required}
                    >
                        {EXISTCUSTOMER.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    {this.renderDrivenShaftLength()}

                    <Field
                        name="desiredDrivenFlangeBore"
                        label="Desired Driven Flange Bore/Shaft Fit"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                        validate={required}
                    >
                        {DESIREDDRIVE.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    <Field
                        name="drivenFlangeKeyway"
                        label="Driven Flange Keyway Required?"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                        validate={required}
                    >
                        {DRIVEFLANGE.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    {this.renderDrivenFlangeKeywaySize()}

                    <Field
                        name="misalignmentKnown"
                        label="Misalignment Known?"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                        validate={required}
                    >
                        {EXISTCUSTOMER.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    {this.renderMisalignment()}

                    <Field
                        name="LEF"
                        label="Limited End Float (LEF) Required?"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                        validate={required}
                    >
                        {EXISTCUSTOMER.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    <Field
                        name="pullerHoles"
                        label="Puller Holes Required?"
                        validate={required}
                        component={requiredSelectField}
                        onChange={this.handleChange}
                    >
                        {EXISTCUSTOMER.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    {this.renderPullerHoles()}

                    <Field
                        name="lifingHoles"
                        validate={required}
                        label="Lifing Holes Required?"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                    >
                        {EXISTCUSTOMER.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    {this.renderLifingHoles()}

                    <Field
                        name="setScrews"
                        validate={required}
                        label="Set Screws Required?"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                    >
                        {EXISTCUSTOMER.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    {this.renderSetScrews()}

                    <Field
                        name="splitCover"
                        validate={required}
                        label="Split Cover Required?"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                    >
                        {EXISTCUSTOMER.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    <Field
                        name="balancing"
                        validate={required}
                        label="Balancing Required?"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                    >
                        {EXISTCUSTOMER.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    {this.renderBalancing()}

                    <div className="mb-3 formDiv">
                        <StyledCommon.FormLabel>
              Quantity<em className="required">*</em>
                        </StyledCommon.FormLabel>
                        <div className="field">
                            <Field
                                name="quantity"
                                type="text"
                                component={renderInputCoupling}
                                validate={[number, required, nondecimal]}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>

                    <Field
                        name="requestedDate"
                        label="Requested Delivery Date (DD/MM/YYYY)"
                        validate={[required]}
                        selected={this.state.date}
                        component={renderDatePicker}
                    />

                    <Field
                        name="tva"
                        label="Torisonal Vibration Analysis (TVA) required (chargeable)?"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                        validate={required}
                    >
                        {EXISTCUSTOMER.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    <Field
                        name="otherComments"
                        label="Other Comments (i.e. temperature, block/material hardness, UJ support, vertical application..)"
                        component={renderTextArea}
                        placeholder="Other Comments"
                        onChange={this.handleChange}
                    />
                </div>
            );
        }
    }

    renderHalfCouplingRigid () {
        if (this.props.browseData && this.props.browseData.coupling === 'Half Coupling – Rigid') {
            return (
                <div className="coupling-quote">
                    <b className="coupling-quote-heading">Quote Half Coupling Rigid Product Questions</b>
                    <Field
                        name="standardDistance"
                        label="Standard distance between shaft ends will be applied unless otherwise specificed, do you accept?"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                        validate={required}
                    >
                        {EXISTCUSTOMER.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    {this.renderDistanceBwShaftEnds()}

                    {this.renderDrivingShaftLengthknown()}

                    {this.renderDrivingShaftLength()}

                    <Field
                        name="desiredDriveFlangeBore"
                        label="Desired Drive Flange Bore/Shaft Fit"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                        validate={required}
                    >
                        {DESIREDDRIVE.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    <Field
                        name="driveFlangeKeyway"
                        label="Drive Flange Keyway Required?"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                        validate={required}
                    >
                        {DRIVEFLANGE.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    {this.renderDriveFlangeKeywaySize()}

                    <Field
                        name="misalignmentKnown"
                        label="Misalignment Known?"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                        validate={required}
                    >
                        {EXISTCUSTOMER.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    {this.renderMisalignment()}

                    <Field
                        name="LEF"
                        label="Limited End Float (LEF) Required?"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                        validate={required}
                    >
                        {EXISTCUSTOMER.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    <Field
                        name="pullerHoles"
                        label="Puller Holes Required?"
                        validate={required}
                        component={requiredSelectField}
                        onChange={this.handleChange}
                    >
                        {EXISTCUSTOMER.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    {this.renderPullerHoles()}

                    <Field
                        name="lifingHoles"
                        label="Lifing Holes Required?"
                        validate={required}
                        component={requiredSelectField}
                        onChange={this.handleChange}
                    >
                        {EXISTCUSTOMER.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    {this.renderLifingHoles()}

                    <Field
                        name="setScrews"
                        label="Set Screws Required?"
                        validate={required}
                        component={requiredSelectField}
                        onChange={this.handleChange}
                    >
                        {EXISTCUSTOMER.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    {this.renderSetScrews()}

                    <Field
                        name="balancing"
                        label="Balancing Required?"
                        validate={required}
                        component={requiredSelectField}
                        onChange={this.handleChange}
                    >
                        {EXISTCUSTOMER.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    {this.renderBalancing()}

                    <Field
                        name="flangeHardware"
                        label="Flange Hardware Required?"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                        validate={required}
                    >
                        {EXISTCUSTOMER.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    <Field
                        name="quantity"
                        label="Quantity"
                        validate={[required, nondecimal]}
                        type="text"
                        component={requiredRenderInput}
                        onChange={this.handleChange}
                    />

                    <Field
                        name="requestedDate"
                        label="Requested Delivery Date (DD/MM/YYYY)"
                        validate={[required]}
                        selected={this.state.date}
                        component={renderDatePicker}
                    />

                    <Field
                        name="otherComments"
                        label="Other Comments"
                        component={renderTextArea}
                        placeholder="Other Comments"
                        onChange={this.handleChange}
                    />
                </div>
            );
        }
    }

    renderHalfCouplingFlex () {
        if (this.props.browseData && this.props.browseData.coupling === 'Half Coupling – Flex') {
            return (
                <div className="coupling-quote">
                    <b className="coupling-quote-heading">Quote Half Coupling Flex Product Questions</b>
                    <Field
                        name="standardDistance"
                        label="Standard distance between shaft ends will be applied unless otherwise specificed, do you accept?"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                        validate={required}
                    >
                        {EXISTCUSTOMER.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    {this.renderDistanceBwShaftEnds()}

                    {this.renderDrivingShaftLengthknown()}

                    {this.renderDrivingShaftLength()}

                    <Field
                        name="desiredDriveFlangeBore"
                        label="Desired Drive Flange Bore/Shaft Fit"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                        validate={required}
                    >
                        {DESIREDDRIVE.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    <Field
                        name="driveFlangeKeyway"
                        label="Drive Flange Keyway Required?"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                        validate={required}
                    >
                        {DRIVEFLANGE.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    {this.renderDriveFlangeKeywaySize()}

                    <Field
                        name="misalignmentKnown"
                        label="Misalignment Known?"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                        validate={required}
                    >
                        {EXISTCUSTOMER.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    {this.renderMisalignment()}

                    <Field
                        name="LEF"
                        label="Limited End Float (LEF) Required?"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                        validate={required}
                    >
                        {EXISTCUSTOMER.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    <Field
                        name="pullerHoles"
                        label="Puller Holes Required?"
                        validate={required}
                        component={requiredSelectField}
                        onChange={this.handleChange}
                    >
                        {EXISTCUSTOMER.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    {this.renderPullerHoles()}

                    <Field
                        name="lifingHoles"
                        label="Lifing Holes Required?"
                        validate={required}
                        component={requiredSelectField}
                        onChange={this.handleChange}
                    >
                        {EXISTCUSTOMER.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    {this.renderLifingHoles()}

                    <Field
                        name="setScrews"
                        label="Set Screws Required?"
                        validate={required}
                        component={requiredSelectField}
                        onChange={this.handleChange}
                    >
                        {EXISTCUSTOMER.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    {this.renderSetScrews()}

                    <Field
                        name="splitCover"
                        label="Split Cover Required?"
                        validate={required}
                        component={requiredSelectField}
                        onChange={this.handleChange}
                    >
                        {EXISTCUSTOMER.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    <Field
                        name="balancing"
                        label="Balancing Required?"
                        validate={required}
                        component={requiredSelectField}
                        onChange={this.handleChange}
                    >
                        {EXISTCUSTOMER.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    {this.renderBalancing()}

                    <Field
                        name="flangeHardware"
                        label="Flange Hardware Required?"
                        component={requiredSelectField}
                        onChange={this.handleChange}
                        validate={required}
                    >
                        {EXISTCUSTOMER.map(function (data, key) {
                            return (
                                <option key={key} value={data.key}>
                                    {data.value}
                                </option>
                            );
                        })}
                    </Field>

                    <Field
                        name="quantity"
                        label="Quantity"
                        type="text"
                        validate={[required, nondecimal]}
                        component={requiredRenderInput}
                        onChange={this.handleChange}
                    />

                    <Field
                        name="requestedDate"
                        selected={this.state.date}
                        label="Requested Delivery Date (DD/MM/YYYY)"
                        validate={[required]}
                        component={renderDatePicker}
                    />

                    <Field
                        name="otherComments"
                        label="Other Comments"
                        component={renderTextArea}
                        placeholder="Other Comments"
                        onChange={this.handleChange}
                    />
                </div>
            );
        }
    }

    CustomerNumber () {
        if (this.state.existCustomer === 'Yes') {
            return (
                <div>
                    <div className="mb-3 formDiv">
                        <StyledCommon.FormLabel>Customer Number</StyledCommon.FormLabel>
                        <div className="field">
                            <Field
                                name="customerNumber"
                                type="text"
                                component={renderInputCoupling}
                                validate={[maxLength20]}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                </div>
            );
        }
    }
    CompanyAddresses () {
        if (this.state.existCustomer === 'No') {
            return (
                <div>
                    <div className="mb-3 formDiv">
                        <StyledCommon.FormLabel>
              Company Address Line 1<em className="required">*</em>
                        </StyledCommon.FormLabel>
                        <div className="field">
                            <Field
                                name="address1"
                                type="text"
                                component={renderInputCoupling}
                                validate={[required, maxLength64, customerAddress]}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>

                    <div className="mb-3 formDiv">
                        <StyledCommon.FormLabel>Company Address Line 2</StyledCommon.FormLabel>
                        <div className="field">
                            <Field
                                name="address2"
                                type="text"
                                component={renderInputCoupling}
                                validate={[maxLength64, customerAddress]}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>

                    <div className="mb-3 formDiv">
                        <StyledCommon.FormLabel>
              Company City<em className="required">*</em>
                        </StyledCommon.FormLabel>
                        <div className="field">
                            <Field
                                name="city"
                                type="text"
                                component={renderInputCoupling}
                                validate={[required, maxLength64, charVal]}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>

                    <Field
                        name="state"
                        type="text"
                        component={renderInput}
                        label="Company State"
                        validate={[maxLength64, charVal]}
                        onChange={this.handleChange}
                    />

                    <div className="mb-3 formDiv">
                        <StyledCommon.FormLabel>
              Country <em className="required">*</em>
                        </StyledCommon.FormLabel>
                        <div className="field">
                            <Field name="country" validate={[required]} component={renderSelectField} onChange={this.handleChange}>
                                <option value="">Select</option>
                                {COUNTRYCODE.map(function (data, key) {
                                    return (
                                        <option key={key} value={data.key}>
                                            {data.country}
                                        </option>
                                    );
                                })}
                            </Field>
                        </div>
                    </div>

                    <div className="mb-3 formDiv">
                        <StyledCommon.FormLabel>
              Company Postcode<em className="required">*</em>
                        </StyledCommon.FormLabel>
                        <div className="field">
                            <Field
                                name="zip"
                                type="text"
                                component={renderInputCoupling}
                                validate={[required, maxLength20]}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                </div>
            );
        }
    }

    render () {
        return (
            <StyledCommon.BottomBarPb ref={this.toMain}>
                <StyledCommon.Wrapper>
                    <Header />
                    <StyledCommon.Container>
                        {this.renderList()}
                        <div className="pt-4 form-section">
                            <form name="driverDetailForm" className="contactform" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                                <div className="mb-3 formDiv">
                                    <StyledCommon.FormLabel>
                    Company Name<em className="required">*</em>
                                    </StyledCommon.FormLabel>
                                    <div className="field">
                                        <Field
                                            name="companyName"
                                            type="text"
                                            component={renderInputCoupling}
                                            validate={[required, maxLength64]}
                                            label="Speed (rpm)"
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="mb-3 formDiv">
                                    <StyledCommon.FormLabel>
                    Contact First Name<em className="required">*</em>
                                    </StyledCommon.FormLabel>
                                    <div className="field">
                                        <Field
                                            name="firstName"
                                            type="text"
                                            component={renderInputCoupling}
                                            validate={[required, maxLength36, onlyChar]}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="mb-3 formDiv">
                                    <StyledCommon.FormLabel>
                    Contact Last Name<em className="required">*</em>
                                    </StyledCommon.FormLabel>
                                    <div className="field">
                                        <Field
                                            name="lastName"
                                            type="text"
                                            component={renderInputCoupling}
                                            validate={[required, maxLength36, onlyChar]}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="mb-3 formDiv">
                                    <StyledCommon.FormLabel>
                    Contact Email<em className="required">*</em>
                                    </StyledCommon.FormLabel>
                                    <div className="field">
                                        <Field
                                            name="email"
                                            type="text"
                                            component={renderInputCoupling}
                                            validate={[email, required, maxLength36]}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="mb-2 maindiv">
                                    <StyledCommon.FormLabel>
                    Contact Phone<em className="required">*</em>
                                    </StyledCommon.FormLabel>

                                    <StyledCommon.TwoFieldCode>
                                        <Field
                                            name="countryCode"
                                            validate={[required]}
                                            component={renderSelectCode}
                                            onChange={this.handleChange}
                                        >
                                            <option value="">Select</option>
                                            {COUNTRYCODE.map(function (data, key) {
                                                return (
                                                    <option key={key} value={`${data.calling_code} - ${data.country}`}>
                                                        {data.calling_code} {data.country}
                                                    </option>
                                                );
                                            })}
                                        </Field>

                                        <Field
                                            name="phone"
                                            type="number"
                                            component={renderInputMobile}
                                            validate={[number, required, maxLength13]}
                                            onChange={this.handlenum1Change}
                                        />
                                    </StyledCommon.TwoFieldCode>
                                </div>

                                <div className="mb-3 formDiv">
                                    <StyledCommon.FormLabel>
                    Existing customer <em className="required">*</em>
                                    </StyledCommon.FormLabel>
                                    <div className="field">
                                        <Field
                                            name="existCustomer"
                                            validate={[required]}
                                            component={renderSelectField}
                                            onChange={this.handleChange}
                                        >
                                            {EXISTCUSTOMER.map(function (data, key) {
                                                return (
                                                    <option key={key} value={data.key}>
                                                        {data.value}
                                                    </option>
                                                );
                                            })}
                                        </Field>
                                    </div>
                                </div>
                                {this.CustomerNumber()}

                                <Field
                                    name="companyIndustry"
                                    label="Company Industry"
                                    component={SelectField}
                                    onChange={this.handleChange}
                                >
                                    {INDUSTRY.map(function (data, key) {
                                        return (
                                            <option key={key} value={data.key}>
                                                {data.value}
                                            </option>
                                        );
                                    })}
                                </Field>
                                {this.CompanyAddresses()}

                                {this.renderFullCoupling()}

                                {this.renderHalfCouplingRigid()}

                                {this.renderHalfCouplingFlex()}

                                <StyledCommon.Button type="submit" className="btn">
                                    {' '}
                  Request Quote{' '}
                                </StyledCommon.Button>
                            </form>
                        </div>
                    </StyledCommon.Container>
                </StyledCommon.Wrapper>
            </StyledCommon.BottomBarPb>
        );
    }
}

const mapStateToProps = state => ({
    browseData: state.content.browseData.data,
    response: state.content.pageDataTerms.data,
    information: state.content.couplingInformation,
    getCouplingData: state.content.pageCouplingData,
    couplingUom: state.content.couplingUom
});

export default connect(
    mapStateToProps,
    { submitQuoteForm, SimpleQuoteForm, getCouplingUom, dataUpdate }
)(
    reduxForm({
        form: 'submitQuoteForm',
        touchOnChange: true,
        enableReinitialize: true,
        initialValues: {
            requestedDate: currentDate
        }
    })(Quote)
);
