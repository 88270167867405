import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { connect } from 'react-redux';

import { PATHS } from '../../constants';
import * as StyledCommon from '../page.styled';
import { allEmpty } from '../../actions';

/*
 TYPES
*/

type Props = {
  couplingInfo: Object,
  simpleQuoteData: Object,
  quotedata: Object,
  browseCouplingData: Object,
  browseData: Object,
  history: Object,
  allEmpty: Function
};

class Confirm extends Component<Props> {
    constructor (props) {
        super(props);
        this.state = {
            data: '',
            redirect: false
        };
        this.toMain = React.createRef();
    }

    setRedirect = () => {
        this.props.allEmpty();

        // localStorage.clear();
        localStorage.setItem('searchForm', 'clear');
        this.setState({
            redirect: true
        });
    };

    renderRedirect = () => {
        if (this.state.redirect) {
            localStorage.clear();
            return <Redirect to={PATHS.HOME} />;
        }
    };
    componentDidMount () {
        if (localStorage.getItem('confirmPage') != 'yes') {
            // this.props.history.push(PATHS.HOME);
        } else {
            if (window.performance) {
                if (performance.navigation.type == 1) {
                    if (localStorage.getItem('firstLoad') == '') {
                        localStorage.setItem('firstLoad', 'yes');
                    } else {
                        if (localStorage.getItem('firstLoad') == 'yes') {
                            // this.props.history.push(PATHS.HOME);
                        }
                    }
                }
            }
        }
    }

    information = () => (
        <StyledCommon.Disclamier className="mb-4">
            <p>
                <b>We aim to respond to all quote requests within 2 working days</b>
            </p>
            <p>
        Installations should be initially aligned as accurately as possible – suggested installation misalignment should
        not exceed 25% of the misalignment data. The forces on the driving and driven machinery should be calculated to
        ensure that these do not exceed the manufacturers allowables.
            </p>

            <p>
        For operation above 80% of the declared maximum coupling speed, it is recommended that the coupling is
        dynamically balanced.
            </p>
            <p>Couplings will slip axially when the maximum axial force is reached</p>
            <p>
        When Driven Flange/Shaft Fit or Drive Flange/Shaft Fit is Transitional or Clearance Fit, the use of Set Screws
        are recommended
            </p>
            <p>
        When Limited End Float (LEF) has been chosen, the coupling misalignment will be different to what was displayed
        in the catalogue/app
            </p>
            <p>RB & RBI couplings – Weights and Inertias are based on the minimum bore size</p>
            <p>
        PM couplings - Weights and Inertias are calculated with mean bore for couplings up to and including PM600, and
        with maximum bore for PM900 and above
            </p>
        </StyledCommon.Disclamier>
    );

    directQuote () {
        if (this.props.simpleQuoteData.existCustomer === 'No') {
            return (
                <React.Fragment>
                    <tr>
                        <th>Company Address Line 1</th>
                        <td>{this.props.simpleQuoteData.address1}</td>
                    </tr>

                    <tr>
                        <th>Company Address Line 2</th>
                        <td>{this.props.simpleQuoteData.address2}</td>
                    </tr>

                    <tr>
                        <th>Company City</th>
                        <td>{this.props.simpleQuoteData.city}</td>
                    </tr>

                    <tr>
                        <th>Company State</th>
                        <td>{this.props.simpleQuoteData.state}</td>
                    </tr>

                    <tr>
                        <th>Company Country</th>
                        <td>{this.props.simpleQuoteData.country}</td>
                    </tr>

                    <tr>
                        <th>Zip Code</th>
                        <td>{this.props.simpleQuoteData.zip}</td>
                    </tr>
                </React.Fragment>
            );
        } else {
            return (
                <tr>
                    <th>Customer Number</th>
                    <td>{this.props.simpleQuoteData.customerNumber}</td>
                </tr>
            );
        }
    }

    searchQuote () {
        if (this.props.quotedata.value.existCustomer === 'No') {
            return (
                <React.Fragment>
                    <tr>
                        <th>Company Address Line 1</th>
                        <td>{this.props.quotedata.value.address1}</td>
                    </tr>

                    <tr>
                        <th>Company Address Line 2</th>
                        <td>{this.props.quotedata.value.address2}</td>
                    </tr>

                    <tr>
                        <th>Company City</th>
                        <td>{this.props.quotedata.value.city}</td>
                    </tr>

                    <tr>
                        <th>Company State</th>
                        <td>{this.props.quotedata.value.state}</td>
                    </tr>

                    <tr>
                        <th>Company Country</th>
                        <td>{this.props.quotedata.value.country}</td>
                    </tr>

                    <tr>
                        <th>Zip Code</th>
                        <td>{this.props.quotedata.value.zip}</td>
                    </tr>
                </React.Fragment>
            );
        } else {
            return (
                <tr>
                    <th>Customer Number</th>
                    <td>{this.props.quotedata.value.customerNumber}</td>
                </tr>
            );
        }
    }

    directCustomer () {
        if (this.props.simpleQuoteData) {
            return (
                <StyledCommon.Container>
                    <StyledCommon.Headingspace>
                        <StyledCommon.ServiceLocP>
                            <strong>
                                <p>
                                    {' '}
                                    <img src="thank.png" width="80px" />
                                </p>
                                <h4 className="confirmMsg">
                  Quote Request Submitted <br />
                                    <br />
                  Thank you for using the Renold Coupling Selector
                                </h4>
                            </strong>
                        </StyledCommon.ServiceLocP>
                    </StyledCommon.Headingspace>
                    <StyledCommon.Table className="table">
                        <tbody>
                            <tr>
                                <th colSpan="3" align="center">
                  Quote Detail Confirmation
                                </th>
                            </tr>
                            <tr>
                                <th>Company Name</th>
                                <td>{this.props.simpleQuoteData.companyName}</td>
                            </tr>

                            <tr>
                                <th>Contact First Name</th>
                                <td>{this.props.simpleQuoteData.firstName}</td>
                            </tr>
                            <tr>
                                <th>Contact Last Name</th>
                                <td>{this.props.simpleQuoteData.lastName}</td>
                            </tr>

                            <tr>
                                <th>Contact Email</th>
                                <td>{this.props.simpleQuoteData.email}</td>
                            </tr>

                            <tr>
                                <th>Contact Phone</th>
                                <td>
                  ({this.props.simpleQuoteData.countryCode.split('-')[0]}) {this.props.simpleQuoteData.phone}
                                </td>
                            </tr>
                            {this.directQuote()}
                            <tr>
                                <th>Company Industry</th>
                                <td>{this.props.simpleQuoteData.companyIndustry}</td>
                            </tr>
                        </tbody>
                    </StyledCommon.Table>
                    {this.information()}

                    <StyledCommon.BrowseLinkAnchor onClick={this.setRedirect} href="#" className="mb-4">
            Start New Search
                    </StyledCommon.BrowseLinkAnchor>
                </StyledCommon.Container>
            );
        }
    }

    afterSearchCustomer () {
        if (this.props.quotedata && this.props.browseCouplingData && this.props.couplingInfo) {
            let requestedDateObj = new Date(this.props.quotedata.value.requestedDate);
            let month = requestedDateObj.getMonth() + 1;
            let requestedDate = requestedDateObj.getDate() + '/' + month + '/' + requestedDateObj.getFullYear();

            return (
                <StyledCommon.Container>
                    <StyledCommon.Headingspace>
                        <StyledCommon.ServiceLocP>
                            <strong>
                                <p>
                                    {' '}
                                    <img src="thank.png" width="80px" />
                                </p>
                                <h4 className="confirmMsg">
                  Quote Request Submitted <br />
                                    <br />
                  Thank you for using the Renold Coupling Selector
                                </h4>
                            </strong>
                        </StyledCommon.ServiceLocP>
                    </StyledCommon.Headingspace>
                    <StyledCommon.Table className="table">
                        <tbody>
                            <tr>
                                <th colSpan="3" align="center">
                  Quote Detail Confirmation
                                </th>
                            </tr>

                            <tr>
                                <th>Company Name</th>
                                <td>{this.props.quotedata.value.companyName}</td>
                            </tr>

                            <tr>
                                <th>Contact First Name</th>
                                <td>{this.props.quotedata.value.firstName}</td>
                            </tr>

                            <tr>
                                <th>Contact Last Name</th>
                                <td>{this.props.quotedata.value.lastName}</td>
                            </tr>

                            <tr>
                                <th>Contact Email</th>
                                <td>{this.props.quotedata.value.email}</td>
                            </tr>

                            <tr>
                                <th>Contact Phone</th>
                                <td>
                  ({this.props.quotedata.value.countryCode.split('-')[0]}) {this.props.quotedata.value.phone}
                                </td>
                            </tr>

                            {this.searchQuote()}

                            <tr>
                                <th>Company Industry</th>
                                <td>{this.props.quotedata.value.companyIndustry}</td>
                            </tr>

                            <tr>
                                <th>Quantity</th>
                                <td>{this.props.quotedata.value.quantity}</td>
                            </tr>

                            <tr>
                                <th>Coupling Type</th>
                                <td>{this.props.browseCouplingData.value.couplingType}</td>
                            </tr>

                            <tr>
                                <th>Coupling Size</th>
                                <td>{this.props.browseCouplingData.value.couplingSize}</td>
                            </tr>

                            <tr>
                                <th>Coupling</th>
                                <td>{this.props.browseCouplingData.value.coupling}</td>
                            </tr>

                            <tr>
                                <th>Engagement Type</th>
                                <td>{this.props.browseCouplingData.value.engagementType}</td>
                            </tr>

                            <tr>
                                <th>Requested Delivery Date (DD/MM/YYYY)</th>
                                <td>{requestedDate}</td>
                            </tr>

                            <tr>
                                <th>Driving Equipment</th>
                                <td>{this.props.couplingInfo.value.drivingequipment.replace(/[^A-Z0-9]/gi, ' ')}</td>
                            </tr>

                            <tr>
                                <th>Application</th>
                                <td>{this.props.couplingInfo.value.application}</td>
                            </tr>

                            <tr>
                                <th>Driven Equipment</th>
                                <td>{this.props.couplingInfo.value.drivenequipment}</td>
                            </tr>

                            <tr>
                                <th>Speed (rpm)</th>
                                <td>{this.props.couplingInfo.value.speed}</td>
                            </tr>
                        </tbody>
                    </StyledCommon.Table>
                    {this.information()}

                    <StyledCommon.BrowseLinkAnchor onClick={this.setRedirect} href="#" className="mb-4">
            Start New Search
                    </StyledCommon.BrowseLinkAnchor>
                </StyledCommon.Container>
            );
        }
    }

    renderQuote () {
        if (this.props.browseData) {
            return <div>{this.afterSearchCustomer()}</div>;
        } else {
            return <div>{this.directCustomer()}</div>;
        }
    }

    render () {
        return (
            <StyledCommon.BottomBarPb ref={this.toMain}>
                {this.renderRedirect()}
                <StyledCommon.Wrapper>{this.renderQuote()}</StyledCommon.Wrapper>
            </StyledCommon.BottomBarPb>
        );
    }
}

const mapStateToProps = state => ({
    quotedata: state.content.quoteData[2],
    simpleQuoteData: state.content.simpleQuoteData,
    browseData: state.content.browseData.data,
    browseCouplingData: state.content.quoteData[1],
    couplingInfo: state.content.quoteData[0]
});

export default connect(
    mapStateToProps,
    { allEmpty }
)(Confirm);
