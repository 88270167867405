export const flyWheel = [
    { key: '10', value: '10' },
    { key: '11.5', value: '11.5' },
    { key: '14', value: '14' },
    { key: '18', value: '18' },
    { key: '21', value: '21' },
    { key: '24', value: '24' }
];

export const MOTOR = [
    { key: 'AISE_MOTOR_SERIES_8', value: 'AISE_MOTOR_SERIES_8' },
    { key: 'AISE_MOTOR_SERIES_6', value: 'AISE_MOTOR_SERIES_6' },
    { key: 'Ingot_Motor', value: 'Ingot_Motor' }
];
export const couplingType = [
    { key: '', value: 'Select Coupling Type' },
    { key: 'RB', value: 'RB' },
    { key: 'RBI', value: 'RBI' },
    { key: 'PM', value: 'PM' }
];

export const VIEWS = [
    {
        name: 'Full Coupling',
        value: ['Shaft to Shaft', 'Shaft to Shaft with Increased Shaft Engagement', 'Mill Motor']
    },
    {
        name: 'Half Coupling – Rigid',
        value: ['Shaft to Shaft', 'Shaft to Shaft with Increased Shaft Engagement', 'Mill Motor']
    },
    {
        name: 'Half Coupling – Flex',
        value: [
            'Shaft to Shaft',
            'Shaft to Shaft with Increased Shaft Engagement',
            'Standard SAE Flywheel to Shaft',
            'Standard SAE Flywheel to Shaft with increased Shaft Engagement',
            'Mill Motor'
        ]
    }
];

export const SIZE = [
    {
        name: 'RB',
        value: [0.12, 0.2, 0.24, 0.37, 0.73, 1.15, 2.15, 3.86, 5.5]
    },
    {
        name: 'RBI',
        value: [1.4, 2.1, 2.6, 4, 8, 12, 23, 40, 60]
    },
    {
        name: 'PM',
        value: [0.4, 0.7, 1.3, 3, 6, 8, 12, 18, 27, 40, 60, 90, 130, 180, 270, 400, 600, 900, 1300, 1800, 2700, 4000, 6000]
    }
];

export const MOTORSIZE = [
    {
        name: 'AISE_MOTOR_SERIES_8',
        value: [
            '0.12',
            '0.2',
            '0.24',
            '0.37',
            '0.73',
            '1.15',
            '2.15',
            '618',
            '620',
            '622',
            '802',
            '803',
            '804',
            '806',
            '808',
            '810',
            '812',
            '814',
            '816',
            '818'
        ]
    },
    {
        name: 'AISE_MOTOR_SERIES_6',
        value: [
            '23',
            '40',
            '1.4',
            '2.1',
            '8',
            '602',
            '603',
            '604',
            '606',
            '608',
            '610',
            '612',
            '614',
            '616',
            '618',
            '620',
            '622',
            '624',
            '818'
        ]
    },
    {
        name: 'Ingot_Motor',
        value: [
            '6',
            '12',
            '18',
            '180M',
            '180L',
            '225L',
            '250L',
            '280M',
            '280L',
            '355L',
            '400L',
            '400LX',
            '450L',
            '618',
            '620',
            '622',
            '818'
        ]
    }
];

export const drivingEquipmentValues = {
    Diesel_Engine_1_Cylinder: '2.5',
    Diesel_Engine_2_Cylinder: '2.5',
    Diesel_Engine_3_Cylinder: '2.5',
    Diesel_Engine_4_Cylinder: '2',
    Diesel_Engine_5_Cylinder: '1.8',
    Diesel_Engine_6_Cylinder: '1.7',
    Diesel_Engine_6plus_Cylinder: '1.5',
    Vee_Engine: '1.5',
    Petrol_Engine: '1.5',
    Turbine: '0',
    Electric_Motor: '0',
    Induction_Motor: '0',
    Synchronous_Motor: '1.5',
    VFD_Driven_Motor: '1',
    Synchronous_Converter_6_pulse: '1',
    Synchronous_Converter_12_pulse: '0.5',
    'PWM/Quasi_Square': '0.5',
    Cyclo_Converter: '0.5',
    Cascade_Recovery: '1.5',
    Other: '2.5'
};

export const drivingEquipment = [
    {
        name: 'Select Driving Equipment',
        value: [
            { key: '1', name: '', value: 'Select Driving Equipment' },
            { key: '2', name: 'Diesel_Engine_1_Cylinder', value: 'Diesel Engine 1 Cylinder' },
            { key: '3', name: 'Diesel_Engine_2_Cylinder', value: 'Diesel Engine 2 Cylinder' },
            { key: '4', name: 'Diesel_Engine_3_Cylinder', value: 'Diesel Engine 3 Cylinder' },
            { key: '5', name: 'Diesel_Engine_4_Cylinder', value: 'Diesel Engine 4 Cylinder' },
            { key: '6', name: 'Diesel_Engine_5_Cylinder', value: 'Diesel Engine 5 Cylinder' },
            { key: '7', name: 'Diesel_Engine_6_Cylinder', value: 'Diesel Engine 6 Cylinder' },
            { key: '8', name: 'Diesel_Engine_6plus_Cylinder', value: 'Diesel Engine 6+ Cylinder' },
            { key: '9', name: 'Vee_Engine', value: 'Vee Engine' },
            { key: '10', name: 'Petrol_Engine', value: 'Petrol Engine' },
            { key: '11', name: 'Turbine', value: 'Turbine' },
            { key: '12', name: 'Electric_Motor', value: 'Electric Motor' },
            { key: '13', name: 'Induction_Motor', value: 'Induction Motor' },
            { key: '14', name: 'Synchronous_Motor', value: 'Synchronous Motor' },
            { key: '15', name: 'VFD_Driven_Motor', value: 'VFD Driven Motor' },
            { key: '16', name: 'Synchronous_Converter_6_pulse', value: 'Synchronous Converter (LCI) - 6 pulse' },
            { key: '17', name: 'Synchronous_Converter_12_pulse', value: 'Synchronous Converter (LCI) - 12 pulse' },
            { key: '18', name: 'PWM/Quasi_Square', value: 'PWM/Quasi Square' },
            { key: '19', name: 'Cyclo_Converter', value: 'Cyclo Converter' },
            { key: '20', name: 'Cascade_Recovery', value: 'Cascade Recovery (Kramer, Scherbius)' },
            { key: '21', name: 'Other', value: 'Other (consult engineering)' }
        ]
    },
    {
        name: 'RB',
        value: [
            { key: '1', name: '', value: 'Select Driving Equipment' },
            { key: '2', name: 'Diesel_Engine_1_Cylinder', value: 'Diesel Engine 1 Cylinder' },
            { key: '3', name: 'Diesel_Engine_2_Cylinder', value: 'Diesel Engine 2 Cylinder' },
            { key: '4', name: 'Diesel_Engine_3_Cylinder', value: 'Diesel Engine 3 Cylinder' },
            { key: '5', name: 'Diesel_Engine_4_Cylinder', value: 'Diesel Engine 4 Cylinder' },
            { key: '6', name: 'Diesel_Engine_5_Cylinder', value: 'Diesel Engine 5 Cylinder' },
            { key: '7', name: 'Diesel_Engine_6_Cylinder', value: 'Diesel Engine 6 Cylinder' },
            { key: '8', name: 'Diesel_Engine_6plus_Cylinder', value: 'Diesel Engine 6+ Cylinder' },
            { key: '9', name: 'Vee_Engine', value: 'Vee Engine' },
            { key: '10', name: 'Petrol_Engine', value: 'Petrol Engine' }
        ]
    },
    {
        name: 'RBI',
        value: [
            { key: '1', name: '', value: 'Select Driving Equipment' },
            { key: '11', name: 'Turbine', value: 'Turbine' },
            { key: '12', name: 'Electric_Motor', value: 'Electric Motor' },
            { key: '13', name: 'Induction_Motor', value: 'Induction Motor' },
            { key: '14', name: 'Synchronous_Motor', value: 'Synchronous Motor' },
            { key: '15', name: 'VFD_Driven_Motor', value: 'VFD Driven Motor' },
            { key: '16', name: 'Synchronous_Converter_6_pulse', value: 'Synchronous Converter (LCI) - 6 pulse' },
            { key: '17', name: 'Synchronous_Converter_12_pulse', value: 'Synchronous Converter (LCI) - 12 pulse' },
            { key: '18', name: 'PWM/Quasi_Square', value: 'PWM/Quasi Square' },
            { key: '19', name: 'Cyclo_Converter', value: 'Cyclo Converter' },
            { key: '20', name: 'Cascade_Recovery', value: 'Cascade Recovery (Kramer, Scherbius)' },
            { key: '21', name: 'Other', value: 'Other (consult engineering)' }
        ]
    },
    {
        name: 'PM',
        value: [
            { key: '1', name: '', value: 'Select Driving Equipment' },
            { key: '11', name: 'Turbine', value: 'Turbine' },
            { key: '12', name: 'Electric_Motor', value: 'Electric Motor' },
            { key: '13', name: 'Induction_Motor', value: 'Induction Motor' },
            { key: '14', name: 'Synchronous_Motor', value: 'Synchronous Motor' },
            { key: '15', name: 'VFD_Driven_Motor', value: 'VFD Driven Motor' },
            { key: '16', name: 'Synchronous_Converter_6_pulse', value: 'Synchronous Converter (LCI) - 6 pulse' },
            { key: '17', name: 'Synchronous_Converter_12_pulse', value: 'Synchronous Converter (LCI) - 12 pulse' },
            { key: '18', name: 'PWM/Quasi_Square', value: 'PWM/Quasi Square' },
            { key: '19', name: 'Cyclo_Converter', value: 'Cyclo Converter' },
            { key: '20', name: 'Cascade_Recovery', value: 'Cascade Recovery (Kramer, Scherbius)' },
            { key: '21', name: 'Other', value: 'Other (consult engineering)' }
        ]
    }
];
