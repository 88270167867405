import {
    SEARCH_COUPLING,
    FETCH_SEARCH_COUPLING_API,
    GET_COUPLING,
    COUPLING_INFORMATION,
    ACTIVE_SLIDE,
    GET_MAX_SPEED,
    DRIVING_DIAMETER,
    DRIVEN_DIAMETER,
    GET_BROWSE_DATA,
    ADD_QUOTE,
    SIMPLE_QUOTE,
    APPLICATION,
    GET_UOM,
    DATA_UPDATE,
    EMPTY_RESULT,
    EMPTY_BROWSE,
    GET_QUOTE_ACTIVE,
    GET_BROWSE_ACTIVE,
    GET_COULPING_SEARCH,
    ALLEMPTY,
    DRIVEN_EQUIPMENT,
    COUPLINGTYPECHANGE
} from '../actions/types';

const INITIAL_STATE = {
    pageDataTerms: [],
    pageDataPrivacy: [],
    response: [],
    activeSlide: [],
    pageCouplingData: [],
    drivingDiamter: [],
    drivenDiamter: [],
    couplingInformation: [],
    browseData: [],
    application: [],
    quoteData: [],
    simpleQuoteData: [],
    couplingUom: [],
    drivenEquipment: [],
    isLoading: false,
    getQuoteActive: false,
    getBrowseActive: false,
    couplingTypeValue: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case SEARCH_COUPLING:
        return { ...state, pageDataPrivacy: action.payload, isLoading: false };
    case FETCH_SEARCH_COUPLING_API:
        return { ...state, pageDataPrivacy: action.payload, isLoading: false };
    case GET_COUPLING:
        return { ...state, pageCouplingData: action.payload, isLoading: false };
    case COUPLING_INFORMATION:
        return { ...state, couplingInformation: action.payload, isLoading: false };
    case ACTIVE_SLIDE:
        return { ...state, activeSlide: action.payload, isLoading: false };
    case GET_MAX_SPEED:
        return { ...state, response: action.payload, isLoading: false };
    case DRIVING_DIAMETER:
        return { ...state, drivingDiamter: action.payload, isLoading: false };
    case DRIVEN_DIAMETER:
        return { ...state, drivenDiamter: action.payload, isLoading: false };
    case GET_BROWSE_DATA:
        return { ...state, browseData: action.payload, isLoading: false };
    case ADD_QUOTE:
        return { ...state, quoteData: action.payload, isLoading: false };
    case SIMPLE_QUOTE:
        return { ...state, simpleQuoteData: action.payload, isLoading: false };
    case APPLICATION:
        return { ...state, application: action.payload, isLoading: false };
    case GET_UOM:
        return { ...state, couplingUom: action.payload, isLoading: false };
    case DATA_UPDATE:
        return { ...state, [action.payload.prop]: action.payload.value };
    case EMPTY_RESULT:
        return { ...state, pageDataPrivacy: action.payload, isLoading: false };
    case EMPTY_BROWSE:
        return { ...state, response: action.payload, isLoading: false };
    case GET_QUOTE_ACTIVE:
        return { ...state, getQuoteActive: action.payload, isLoading: false };
    case GET_BROWSE_ACTIVE:
        return { ...state, getBrwoseActive: action.payload, isLoading: false };
    case GET_COULPING_SEARCH:
        return { ...state, getSearchFields: action.payload, isLoading: false };
    case ALLEMPTY:
        return { ...state, redirect: '', isLoading: false };
    case DRIVEN_EQUIPMENT:
        return { ...state, drivenEquipment: action.payload, isLoading: false };
    case COUPLINGTYPECHANGE:
        return { ...state, couplingTypeValue: action.payload, isLoading: false };
    default:
        return state;
    }
};
