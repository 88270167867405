import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import * as StyledCommon from '../page.styled';
import { Header } from '../includes';
import { PATHS, kNm } from '../../constants';
import { activeSlide, getCouplingUom, getBrowseActive, allEmpty } from '../../actions';

/*
 TYPES
*/

type Props = {
  fetchSearchCoupling: Function,
  activeSlide: Function,
  searchList: Object,
  response: Object,
  history: Object,
  getCouplingUom: Function,
  couplingUom: Object,
  getBrowseActive: Function,
  information: Object,
  allEmpty: Function
};

class Results extends Component<Props> {
    constructor (props) {
        super(props);
        this.state = {
            redirect: false
        };
    }

    componentDidMount () {
        this.props.getBrowseActive(false);
        this.props.getCouplingUom();
    }

    setRedirect = () => {
        this.props.allEmpty();

        // localStorage.clear();
        localStorage.setItem('searchForm', 'clear');
        this.setState({
            redirect: true
        });
    };
    handleView = id => {
        this.props.activeSlide(id);
        this.props.getBrowseActive(true);
        this.props.history.push(PATHS.BROWSE);
    };

    renderRedirect = () => {
        if (this.state.redirect) {
            localStorage.clear();
            return <Redirect to={PATHS.HOME} />;
        }
    };

    renderList () {
        if (this.props.response.data) {
            if (this.props.response.data.data == '') {
                return (
                    <tr>
                        <td colSpan="5">
                            <StyledCommon.WarningInfo>
                                <strong>Info!</strong> Standard Product is unsuitable for your application, consult Renold engineering
                team for a solution.
                                <p>Would you like to send an email to Renold for a consultation?</p>
                                <StyledCommon.QueryAccept href={PATHS.QUOTE} className="yes">
                  Yes
                                </StyledCommon.QueryAccept>
                                <StyledCommon.QueryAccept onClick={this.setRedirect}>No</StyledCommon.QueryAccept>
                            </StyledCommon.WarningInfo>
                        </td>
                    </tr>
                );
            } else {
                if (this.props.response.data.data) {
                    let count = 1;
                    return this.props.response.data.data.map((stream, index) => (
                        <tr key={count++}>
                            <td className="coupling-data">
                                <span onClick={() => this.handleView(index)}>{stream.couplingName}</span>
                            </td>
                            <td>{stream.engagementType}</td>
                            <td>{stream.maxSpeed}</td>
                            <td>
                                {this.props.information.maxtorqueuom == 'lb-ft'
                                    ? stream.maxTorque
                                    : (stream.maxTorque / kNm).toFixed(3)}{' '}
                                {this.props.information.maxtorqueuom}
                            </td>
                            <td>
                                {this.props.information.maxtorqueuom == 'lb-ft'
                                    ? stream.nominalTorque
                                    : (stream.nominalTorque / kNm).toFixed(3)}{' '}
                                {this.props.information.maxtorqueuom}
                            </td>
                        </tr>
                    ));
                }
            }
        }
    }

    navigateBack = () => {
        this.props.history.push(PATHS.HOME);
    };

    render () {
        return (
            <StyledCommon.BottomBarPb>
                {this.renderRedirect()}
                <StyledCommon.Wrapper>
                    <div className="resultHeader">
                        <Header />
                        <StyledCommon.EditSearch onClick={this.navigateBack}>Edit Search</StyledCommon.EditSearch>
                    </div>

                    <StyledCommon.Container>
                        <h1 className="page-heading">Search Results</h1>
                        <div className="pt-4 form-section">
                            <StyledCommon.Table className="table table-responsive">
                                <thead>
                                    <tr>
                                        <th>Coupling Name</th>
                                        <th>Engagement Type</th>
                                        <th>Max Speed (rpm)</th>
                                        <th>Max Torque Tkmax</th>
                                        <th>Nominal Torque TKN</th>
                                    </tr>
                                </thead>
                                <tbody>{this.renderList()}</tbody>
                            </StyledCommon.Table>
                        </div>
                    </StyledCommon.Container>
                </StyledCommon.Wrapper>
            </StyledCommon.BottomBarPb>
        );
    }
}

const mapStateToProps = state => ({
    response: state.content.pageDataPrivacy,
    couplingUom: state.content.couplingUom,
    information: state.content.couplingInformation
});

export default connect(
    mapStateToProps,
    { activeSlide, getCouplingUom, getBrowseActive, allEmpty }
)(Results);
