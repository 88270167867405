export const FETCH_CATEGORY = 'FETCH_CATEGORY';
export const FETCH_LOCATIONS = 'FETCH_LOCATIONS';
export const SEARCH_COUPLING = 'SEARCH_COUPLING';
export const FETCH_SEARCH_COUPLING_API = 'FETCH_SEARCH_COUPLING_API';
export const GET_COUPLING = 'GET_COUPLING';
export const COUPLING_INFORMATION = 'COUPLING_INFORMATION';
export const ACTIVE_SLIDE = 'ACTIVE_SLIDE';
export const GET_MAX_SPEED = 'GET_MAX_SPEED';
export const DRIVING_DIAMETER = 'DRIVING_DIAMETER';
export const DRIVEN_DIAMETER = 'DRIVEN_DIAMETER';
export const GET_BROWSE_DATA = 'GET_BROWSE_DATA';
export const ADD_QUOTE = 'ADD_QUOTE';
export const APPLICATION = 'APPLICATION';
export const SIMPLE_QUOTE = 'SIMPLE_QUOTE';
export const GET_UOM = 'TOGGLE_PATHS';
export const DATA_UPDATE = 'DATA_UPDATE';
export const EMPTY_RESULT = 'EMPTY_RESULT';
export const EMPTY_BROWSE = 'EMPTY_BROWSE';
export const GET_QUOTE_ACTIVE = 'GET_QUOTE_ACTIVE';
export const GET_BROWSE_ACTIVE = 'GET_BROWSE_ACTIVE';
export const GET_COULPING_SEARCH = 'GET_COULPING_SEARCH';
export const ALLEMPTY = 'ALLEMPTY';
export const DRIVEN_EQUIPMENT = 'DRIVEN_EQUIPMENT';
export const COUPLINGTYPECHANGE = 'COUPLINGTYPECHANGE';
