import * as styledComponents from 'styled-components';
import { Link } from 'react-router-dom';

const { default: styled } = styledComponents;

// Navbar css

export const HeaderLinkContainer = styled.div`
  display: flex;

  border-top: 1px solid #efefef;
  width: 100%;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 9;
  justify-content: center;
`;

export const BottomBarPb = styled.div``;

export const TwoField = styled.div`
  width: 55%;
  display: flex;
`;

export const TwoFieldCode = styled.div`
  width: 55%;
  display: flex;
`;

export const Button = styled.button`
  color: #fff;
  background: #004990;
  padding: 10px;
  font-weight: 500;
  border-radius: 4px;
  display: block;
  text-align: center;
  box-shadow: 0px 2px 0 #ddd;
  width: 100%;
  margin: auto;
  font-size: 16px;
`;
export const Container = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`;

export const Wrapper = styled.div`
  display: block;
`;

export const ServiceLocP = styled.p`
  font-weight: 300;
  text-align: center;
`;

export const HeaderLink = styled.span``;

export const FormLabel = styled.label`
  margin-bottom: 10px;
  width: 40%;
  color: #5c5d60;
  padding-top: 5px;

  margin-right: 5%;
  align-items: center;
`;

export const Table = styled.table`
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  td,
  th {
    border: 1px solid #dee2e6;
  }
`;

export const Field = styled.div`
  width: 50%;
  margin-left: 15px;
`;

export const Field1 = styled.div`
  width: 50%;
`;

export const FieldMobile = styled.div`
  width: 70%;
`;
export const HeaderSpace = styled.div`
  padding: 2%;
`;

export const FormSelect = styled.select`
  height: 36px;
  border-radius: 0;
  border-top: none;
  margin-bottom: 10px;
  font-size: 0.9em;
  color: #a2a3a5;
  font-weight: 300;
  border-left: none;
  border-right: none;
  padding-left: 6px;
  padding-right: 6px;
`;

export const Disclamier = styled.div`
  margin: auto;
  width: 94%;
  margin-top: 2%;
  text-align: justify;
`;
export const Headingspace = styled.div`
  padding: 2%;
  font-size: 13px;
`;
export const error = styled.span`
  font-size: 11px;
  color: #f00;
  width: 100%;
  display: block;
  margin-top: -10px;
`;

export const BrowseLink = styled(Link)`
  color: #fff;
  background: #004990;
  padding: 10px;
  font-weight: 500;
  border-radius: 4px;
  display: block;
  text-align: center;
  box-shadow: 0px 2px 0 #ddd;
  width: 100%;
  margin: auto;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    color: #fff;
    text-decoration: none;
  }
`;

export const BrowseLinkAnchor = styled.a`
  color: #fff;
  background: #004990;
  padding: 10px;
  font-weight: 500;
  border-radius: 4px;
  display: block;
  text-align: center;
  box-shadow: 0px 2px 0 #ddd;
  width: 100%;
  margin: auto;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    color: #fff;
    text-decoration: none;
  }
`;

export const FormInput = styled.input`
  height: 36px;
  border-radius: 0;
  border: 1px solid #a8a8a8;
  margin-bottom: 10px;
  font-size: 0.9em;
  color: #a2a3a5;
  font-weight: 300;
  border-left: none;
  border-right: none;
  border-top: none;
  padding-left: 6px;
  padding-right: 6px;
`;

export const NavBar = styled.div`
  a {
    color: #5c5d60;
  }
`;

export const WarningMessage = styled.div`
  background: #fff3cd;
  border-radius: 0.25rem;
  color: #856404;
  border: 1px solid #ffeeba;
  font-size: 14px;
  padding: 0.75rem 1.25rem;
  line-height: 1.5;
`;

export const WarningInfo = styled.div`
  background: #b3d3ff;
  border-radius: 0.25rem;
  color: #004990;
  border: 1px solid #b3d3ff;
  font-size: 14px;
  padding: 0.75rem 1.25rem;
  line-height: 1.5;
  margin-top: 30px;
`;

export const QueryAccept = styled.a`
  & {
    color: white !important;
    padding: 7px 30px;
    background: #004990;
    margin-left: 10px;
    text-decoration: none;
  }

  &:hover {
    color: white;
    text-decoration: none;
  }
`;

export const EditSearch = styled(Link)`
  position: absolute;
  padding: 5px 15px;
  color: #004990;
  margin-right: 15px;
  right: 0;
  top: 5px;
  text-decoration: underline;
  font-weight: 800;
`;

export const AdminEmail = styled.p`
  background-color: rgb(240, 240, 240);
  border-radius: 37px;
  width: 200px;
  margin: 0 auto;
  margin-top: 10px;
`;

export const ContactButtonLink = styled.span`
  flex-grow: 1;
  padding: 5px 14px 9px 0;
  display: block;
  text-align: center;
  background: #fff;
  color: #314575;
  border-bottom: 5px solid #fff;
  font-weight: 400;
  &:first-child {
    margin-right: 0;
  }
  &:last-child {
    margin-left: 0;
  }
  &.first-radio {
    margin-left: 0;
    margin-right: 0;
  }
  &.active-radio {
    color: #314575;
    border-bottom: 5px solid #314575;
    font-weight: 600;
  }
`;

export const ContactButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RadioButtonsLabel = styled.label`
  width: 50%;
  position: relative;
  border: 1px solid #a8a8a8;
  &:first-child {
    border-right: none;
  }
`;
