// @flow

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import * as Styled from '../page.styled';
import { PATHS } from '../../constants';

/*
 TYPES
*/

type Props = {
  history: Object,
  couplingDetail: Object,
  response: Object,
  browseActive: Function,
  quoteActive: Function
};

class Header extends Component<Props> {
    render () {
        let result = '#';
        let browse = '#';
        let quote = '#';

        if (this.props.response.data) {
            if (this.props.response.data.data.length > 0) {
                result = PATHS.RESULTS;
            }
            // if (this.props.couplingDetail.data) {
            //     browse = PATHS.BROWSE;
            // }
        }

        if (this.props.browseActive) {
            browse = PATHS.BROWSE;
        }
        if (this.props.quoteActive) {
            quote = PATHS.QUOTE;
        }

        return (
            <Styled.HeaderLinkContainer className="fixed-header">
                <Styled.NavBar className="header-links">
                    <NavLink exact to={PATHS.HOME}>
                        <Styled.HeaderLink> Search </Styled.HeaderLink>
                    </NavLink>
                    <NavLink to={result}>
                        <Styled.HeaderLink> Results </Styled.HeaderLink>
                    </NavLink>
                    <NavLink to={browse}>
                        <Styled.HeaderLink> Browse </Styled.HeaderLink>
                    </NavLink>
                    <NavLink to={quote}>
                        <Styled.HeaderLink> Quote </Styled.HeaderLink>
                    </NavLink>
                </Styled.NavBar>
            </Styled.HeaderLinkContainer>
        );
    }
}

// export default Header;

const mapStateToProps = state => ({
    response: state.content.pageDataPrivacy,
    couplingDetail: state.content.response,
    quoteActive: state.content.getQuoteActive,
    browseActive: state.content.getBrwoseActive
});

export default connect(
    mapStateToProps,
    {}
)(Header);
