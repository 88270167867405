import React, { Component } from 'react';

import * as StyledCommon from '../page.styled';
import { Header } from '../includes';

/*
 TYPES
*/

type Props = {
  handleSubmit: Function,
  submitQuoteForm: Function,
  response: Object
};

class Error404 extends Component<Props> {
    render () {
        return (
            <StyledCommon.BottomBarPb>
                <StyledCommon.Wrapper>
                    <Header />
                    <StyledCommon.Container>
                        <div>
                            <p>Page not found.</p>
                        </div>
                    </StyledCommon.Container>
                </StyledCommon.Wrapper>
            </StyledCommon.BottomBarPb>
        );
    }
}

export default Error404;
