import axios from 'axios';

import {
    FETCH_CATEGORY,
    FETCH_LOCATIONS,
    SEARCH_COUPLING,
    FETCH_SEARCH_COUPLING_API,
    GET_COUPLING,
    COUPLING_INFORMATION,
    ACTIVE_SLIDE,
    GET_MAX_SPEED,
    DRIVING_DIAMETER,
    DRIVEN_DIAMETER,
    GET_BROWSE_DATA,
    ADD_QUOTE,
    APPLICATION,
    SIMPLE_QUOTE,
    GET_UOM,
    DATA_UPDATE,
    EMPTY_RESULT,
    EMPTY_BROWSE,
    GET_QUOTE_ACTIVE,
    GET_BROWSE_ACTIVE,
    GET_COULPING_SEARCH,
    ALLEMPTY,
    DRIVEN_EQUIPMENT,
    COUPLINGTYPECHANGE
} from './types';
import { API_PATHS } from '../constants';

export const fetchCategory = () => async dispatch => {
    try {
        const response = await axios.get(API_PATHS.SERVICE_CATEGORY_API);
        dispatch({ type: FETCH_CATEGORY, payload: response.data });
    } catch (e) {
        return e;
    }
};

export const fetchLocationList = (categoryId, subCategoryId = '') => async dispatch => {
    try {
        const response = await axios.get(API_PATHS.SERVICE_LOCATOR_API, {
            params: {
                categoryId: categoryId,
                subCategoryId: subCategoryId
            }
        });
        dispatch({ type: FETCH_LOCATIONS, payload: response.data });
    } catch (e) {
        return e;
    }
};

export const fetchApplicationList = () => async dispatch => {
    try {
        const response = await axios.get(API_PATHS.APPLICATION_API);
        dispatch({ type: APPLICATION, payload: response.data });
    } catch (e) {
        return e;
    }
};

// Search coupling
export const searchCoupling = formValues => async dispatch => {
    try {
        let response = [];
        if (API_PATHS.SEARCH_COUPLING) {
            const instance = axios.create();
            instance.defaults.headers.common['Content-Type'] = 'application/json';
            response = await instance.post(API_PATHS.SEARCH_COUPLING, {
                body: formValues
            });
        }
        dispatch({ type: SEARCH_COUPLING, payload: response });
    } catch (e) {
        return e;
    }
};

// Fetch Search Result

export const fetchSearchCoupling = () => async dispatch => {
    try {
        const instance = axios.create();
        instance.defaults.headers.common['Content-Type'] = 'application/json';
        const response = await instance.get(API_PATHS.FETCH_SEARCH_COUPLING_API);
        dispatch({ type: FETCH_SEARCH_COUPLING_API, payload: response.data });
    } catch (e) {
        return e;
    }
};

export const getCouplingDetail = couplingId => async dispatch => {
    try {
        const params = {
            couplingId: couplingId
        };
        const instance = axios.create();
        instance.defaults.headers.common['Content-Type'] = 'application/json';
        const response = await instance.get(API_PATHS.GET_COUPLING_RECORD_API, {
            params: params
        });
        dispatch({ type: GET_COUPLING, payload: response.data.data });
    } catch (e) {
        return e;
    }
};

export const couplingInformation = information => async dispatch => {
    try {
        dispatch({ type: COUPLING_INFORMATION, payload: information });
    } catch (e) {
        return e;
    }
};

export const activeSlide = couplingId => async dispatch => {
    try {
        dispatch({ type: ACTIVE_SLIDE, payload: couplingId });
    } catch (e) {
        return e;
    }
};

export const getMaxSpeed = () => async dispatch => {
    try {
        const instance = axios.create();
        instance.defaults.headers.common['Content-Type'] = 'application/json';
        const response = await instance.get(API_PATHS.GET_MAX_SPEED);
        dispatch({ type: GET_MAX_SPEED, payload: response.data });
    } catch (e) {
        return e;
    }
};

export const getDrivingDiameter = () => async dispatch => {
    try {
        const instance = axios.create();
        instance.defaults.headers.common['Content-Type'] = 'application/json';
        const response = await instance.get(API_PATHS.GET_DIAMETER);
        dispatch({ type: DRIVING_DIAMETER, payload: response.data });
    } catch (e) {
        return e;
    }
};

export const getDrivenDiameter = () => async dispatch => {
    try {
        const instance = axios.create();
        instance.defaults.headers.common['Content-Type'] = 'application/json';
        const response = await instance.get(API_PATHS.GET_DRIVEN);
        dispatch({ type: DRIVEN_DIAMETER, payload: response.data });
    } catch (e) {
        return e;
    }
};

export const getBrosweDetail = couplingId => async dispatch => {
    try {
        const params = {
            couplingId: couplingId
        };
        const instance = axios.create();
        instance.defaults.headers.common['Content-Type'] = 'application/json';
        const response = await instance.get(API_PATHS.GET_COUPLING_RECORD_API, {
            params: params
        });
        dispatch({ type: GET_BROWSE_DATA, payload: response.data });
    } catch (e) {
        return e;
    }
};

export const submitQuoteForm = formValues => async dispatch => {
    try {
        if (API_PATHS.SUBMIT_QUOTE_API) {
            const instance = axios.create();
            instance.defaults.headers.common['Content-Type'] = 'application/json';
            await instance.post(API_PATHS.SUBMIT_QUOTE_API, {
                body: formValues
            });
        }
        dispatch({ type: ADD_QUOTE, payload: formValues });
    } catch (e) {
        return e;
    }
};

export const SimpleQuoteForm = formValues => async dispatch => {
    try {
        if (API_PATHS.SUBMIT_QUOTE_API) {
            const instance = axios.create();
            instance.defaults.headers.common['Content-Type'] = 'application/json';
            await instance.post(API_PATHS.SIMPLE_QUOTE_API, {
                body: formValues
            });
        }
        dispatch({ type: SIMPLE_QUOTE, payload: formValues });
    } catch (e) {
        return e;
    }
};

export const getCouplingUom = () => async dispatch => {
    try {
        const instance = axios.create();
        instance.defaults.headers.common['Content-Type'] = 'application/json';
        const response = await instance.get(API_PATHS.GET_COUPLING_UOM_API);
        dispatch({ type: GET_UOM, payload: response.data });
    } catch (e) {
        return e;
    }
};

export const dataUpdate = ({ prop, value }) => ({
    type: DATA_UPDATE,
    payload: { prop, value }
});

export const getResultEmpty = () => async dispatch => {
    dispatch({ type: EMPTY_RESULT, payload: '' });
};

export const getBrowseEmpty = () => async dispatch => {
    dispatch({ type: EMPTY_BROWSE, payload: '' });
};
export const getQuoteActive = value => async dispatch => {
    dispatch({ type: GET_QUOTE_ACTIVE, payload: value });
};

export const getBrowseActive = value => async dispatch => {
    dispatch({ type: GET_BROWSE_ACTIVE, payload: value });
};

export const getSearchFormCoupling = value => async dispatch => {
    dispatch({ type: GET_COULPING_SEARCH, payload: value });
};

export const allEmpty = () => async dispatch => {
    dispatch({ type: ALLEMPTY, payload: '' });
};

export const getDrivenEquipmentValue = value => async dispatch => {
    dispatch({ type: DRIVEN_EQUIPMENT, payload: value });
};

export const couplingTypeChange = couplingType => async dispatch => {
    dispatch({ type: COUPLINGTYPECHANGE, payload: couplingType });
};
